var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, message, Popconfirm, Table } from 'antd';
import produce from 'immer';
import { get } from 'lodash';
import styled from 'styled-components';
import { sendPv } from '@utils/umeng';
import BindPrintCaseModal from './BindPrintCaseModal';
var PrintCasesTable = styled(Table).withConfig({ displayName: "PrintCasesTable", componentId: "sc-1orjoap" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 20px;\n"], ["\n  margin-top: 20px;\n"])));
var PrintCases = /** @class */ (function (_super) {
    __extends(PrintCases, _super);
    function PrintCases() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            editPrintCase: {
                isEditMode: false,
                visible: false,
                initialValue: {}
            }
        };
        _this.columns = [
            {
                title: '打印机方案名称',
                dataIndex: 'schemeName',
                key: 'schemeName'
            },
            {
                title: '打印单据',
                key: 'printTypes',
                render: function (text, record) {
                    var namePrefix = '外送单';
                    return record.printTypes
                        .map(function (type) {
                        return namePrefix + "\uFF08" + type.name + "\uFF09";
                    })
                        .join('，');
                }
            },
            {
                title: '所在门店',
                dataIndex: 'storeName',
                key: 'storeName'
            },
            {
                title: '操作',
                key: 'action',
                render: function (text, record) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement(Button, { size: "small", onClick: function () { return _this.toggleEditPrintCase(record); } }, "\u7F16\u8F91"),
                        "\u00A0",
                        React.createElement(Popconfirm, { title: "\u786E\u8BA4\u5220\u9664\uFF1F", cancelText: "\u53D6\u6D88", okText: "\u786E\u5B9A", onConfirm: function () { return _this.handleDelete(record.id); } },
                            React.createElement(Button, { size: "small" }, "\u5220\u9664"))));
                }
            }
        ];
        _this.handleDelete = function (id) {
            _this.props.deletePrintCase(id).then(function () {
                message.success('打印方案删除成功');
            });
        };
        _this.toggleEditPrintCase = function (initialValue) {
            if (initialValue === void 0) { initialValue = {}; }
            var editPrintCase = _this.state.editPrintCase;
            _this.setState(produce(function (draft) {
                draft.editPrintCase = {
                    initialValue: initialValue,
                    isEditMode: !!get(initialValue, 'id'),
                    visible: !editPrintCase.visible
                };
            }));
        };
        _this.handleTableChange = function (pagination) {
            _this.props.getPrintCaseList({
                page: pagination.current,
                // pageSize: pagination.pageSize
                pageSize: 999
            });
        };
        return _this;
    }
    PrintCases.prototype.componentDidMount = function () {
        this.props.getPrintCaseList({});
        if (this.props.location.state) {
            if (this.props.dataSource.length > 0) {
                var id_1 = this.props.location.state.id;
                var data = this.props.dataSource.filter(function (item) { return item.id === id_1; });
                this.toggleEditPrintCase(data[0]);
            }
        }
        sendPv();
    };
    PrintCases.prototype.render = function () {
        var _this = this;
        var state = this.state;
        var props = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { type: "primary", onClick: function () { return _this.toggleEditPrintCase({}); } }, "\u6DFB\u52A0\u6253\u5370\u65B9\u6848"),
            React.createElement(BindPrintCaseModal, { isEditMode: state.editPrintCase.isEditMode, visible: state.editPrintCase.visible, initialValue: state.editPrintCase.initialValue, toggleVisible: this.toggleEditPrintCase }),
            React.createElement(PrintCasesTable, { columns: this.columns, dataSource: props.dataSource, 
                // pagination={props.pagination}-
                pagination: false, loading: props.listLoading, onChange: this.handleTableChange, rowKey: "id", bordered: true })));
    };
    return PrintCases;
}(React.Component));
var mapState = function (state) {
    var _a = state.printCases, data = _a.data, loading = _a.loading;
    return {
        dataSource: data.list,
        listLoading: loading,
        pagination: {
            current: data.page,
            pageSize: data.pageSize,
            total: data.total
        }
    };
};
var mapDispatch = function (dispatch) { return ({
    getPrintCaseList: dispatch.printCases.fetchData,
    deletePrintCase: dispatch.printCases.deleteItemWithReq
}); };
export default connect(mapState, mapDispatch)(PrintCases);
var templateObject_1;
