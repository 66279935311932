var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ReceiptTplWrapper = styled.div.withConfig({ displayName: "ReceiptTplWrapper", componentId: "sc-cl1p7w" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #f0f2f5;\n  color: #333;\n  font-family: \u5FAE\u8F6F\u96C5\u9ED1;\n"], ["\n  background-color: #f0f2f5;\n  color: #333;\n  font-family: \u5FAE\u8F6F\u96C5\u9ED1;\n"])));
export var ReturnToPrintTpl = styled.div.withConfig({ displayName: "ReturnToPrintTpl", componentId: "sc-u3b4ap" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-left: 30px;\n  height: 60px;\n  line-height: 60px;\n  background: rgba(255, 255, 255, 0.97);\n  box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.06);\n  font-size: 14px;\n  .back {\n    margin-right: 5px;\n  }\n"], ["\n  padding-left: 30px;\n  height: 60px;\n  line-height: 60px;\n  background: rgba(255, 255, 255, 0.97);\n  box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.06);\n  font-size: 14px;\n  .back {\n    margin-right: 5px;\n  }\n"])));
export var ReceiptMain = styled.div.withConfig({ displayName: "ReceiptMain", componentId: "sc-b2knze" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 30px 30px 74px;\n  margin-top: 5px;\n  background: #fff;\n  display: flex;\n  .receipt-preview {\n    /* width: 640px; */\n    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);\n  }\n"], ["\n  padding: 30px 30px 74px;\n  margin-top: 5px;\n  background: #fff;\n  display: flex;\n  .receipt-preview {\n    /* width: 640px; */\n    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);\n  }\n"])));
export var ReceiptContent = styled.div.withConfig({ displayName: "ReceiptContent", componentId: "sc-13n0nvv" })(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var Buttons = styled.div.withConfig({ displayName: "Buttons", componentId: "sc-1xw4trr" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);\n  height: 59px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding-right: 43px;\n  background-color: #fff;\n"], ["\n  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);\n  height: 59px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding-right: 43px;\n  background-color: #fff;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
