var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var RankItem = styled.div.withConfig({ displayName: "RankItem", componentId: "sc-15qowu8" })(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var RankTableSection = styled.section.withConfig({ displayName: "RankTableSection", componentId: "sc-ubqsjh" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 30px 0 50px;\n"], ["\n  margin: 30px 0 50px;\n"])));
export var TableRemark = styled.span.withConfig({ displayName: "TableRemark", componentId: "sc-d7khm1" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 30px;\n"], ["\n  margin-left: 30px;\n"])));
export var TableTipTitle = styled.span.withConfig({ displayName: "TableTipTitle", componentId: "sc-1ilkkca" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: 5px;\n"], ["\n  margin-left: 5px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
