var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
export var InfoWrapper = styled.div.withConfig({ displayName: "InfoWrapper", componentId: "sc-1w0cahe" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0 45px;\n  .cannect {\n    font-size: 14px;\n    font-family: PingFangSC-Regular, PingFang SC;\n    color: #333333;\n    line-height: 14px;\n    margin-bottom: 20px;\n    margin-top: 30px;\n  }\n\n  .title {\n    font-size: 14px;\n    font-family: PingFangSC-Semibold, PingFang SC;\n    font-weight: 600;\n    color: #333333;\n    line-height: 21px;\n  }\n\n  .list {\n    font-size: 12px;\n    font-family: PingFangSC-Semibold, PingFang SC;\n    color: rgba(153, 153, 153, 1);\n    line-height: 21px;\n  }\n"], ["\n  padding: 0 45px;\n  .cannect {\n    font-size: 14px;\n    font-family: PingFangSC-Regular, PingFang SC;\n    color: #333333;\n    line-height: 14px;\n    margin-bottom: 20px;\n    margin-top: 30px;\n  }\n\n  .title {\n    font-size: 14px;\n    font-family: PingFangSC-Semibold, PingFang SC;\n    font-weight: 600;\n    color: #333333;\n    line-height: 21px;\n  }\n\n  .list {\n    font-size: 12px;\n    font-family: PingFangSC-Semibold, PingFang SC;\n    color: rgba(153, 153, 153, 1);\n    line-height: 21px;\n  }\n"])));
var infoData = {
    ebai: [
        '如何查找饿了么零售店铺ID？',
        '操作步骤：',
        '1、登录零售商家版',
        '2、在店铺设置中查看店铺ID'
    ],
    cash: ['收银系统接单：请在收银系统后台自行进行手工接单或设置成自动接单。'],
    default: [
        '本系统接单：用本软件触发平台接单，外卖平台后台请设为手动接单方式。'
    ]
};
var TipInfo = function (_a) {
    var _b = _a.isEbai, isEbai = _b === void 0 ? false : _b, _c = _a.isCash, isCash = _c === void 0 ? false : _c;
    var data = isEbai
        ? infoData.ebai
        : isCash
            ? infoData.cash
            : infoData.default;
    return (React.createElement(InfoWrapper, null,
        !!data.length && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "title" }, "\u00B7\u5C0F\u8D34\u58EB"),
            data.map(function (list) { return (React.createElement("div", { className: "list", key: list }, list)); }))),
        React.createElement("div", { className: "cannect" }, "\u7ED1\u5B9A\u9047\u5230\u95EE\u9898\uFF0C\u53EF\u4EE5\u8054\u7CFB\u6613\u9001\u5BA2\u670D\uFF1A15501240064")));
};
export default TipInfo;
var templateObject_1;
