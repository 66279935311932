var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { useEffect } from 'react';
import { Button, Input, Select } from 'antd';
import './index.less';
import styled from 'styled-components';
import lodash from 'lodash';
var Option = Select.Option;
var WrapperSelect = styled(Select).withConfig({ displayName: "WrapperSelect", componentId: "sc-an53ru" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 20%;\n  .ant-select-selection {\n    border-radius: 20px;\n    /* width: 224px; */\n    background-color: #f6f8fa;\n    border: 0;\n    > div {\n      height: 40px;\n      line-height: 40px;\n    }\n    height: 40px;\n  }\n"], ["\n  width: 20%;\n  .ant-select-selection {\n    border-radius: 20px;\n    /* width: 224px; */\n    background-color: #f6f8fa;\n    border: 0;\n    > div {\n      height: 40px;\n      line-height: 40px;\n    }\n    height: 40px;\n  }\n"])));
var initData = { id: -1, name: '全部门店' };
var OrderSearchHeader = function (props) {
    var _a = __read(React.useState(initData.id), 2), selectStore = _a[0], setStore = _a[1];
    var _b = __read(React.useState([initData]), 2), storeList = _b[0], setStoreList = _b[1];
    var _c = __read(React.useState(null), 2), platformDaySn = _c[0], setPlatformDaySn = _c[1];
    var _d = __read(React.useState(null), 2), phone = _d[0], setphone = _d[1];
    useEffect(function () {
        var search = props.search;
        setStore(Number(lodash.get(search, 'storeId', initData.id)));
        setPlatformDaySn(lodash.get(search, 'platformDaySn', ''));
        setphone(lodash.get(search, 'phone', ''));
    }, [props.tabStatus]);
    useEffect(function () {
        setStoreList(__spread(storeList, props.storeList));
    }, [props.storeList]);
    return (React.createElement("div", { className: "order-search-header" },
        React.createElement(WrapperSelect, { value: selectStore, onChange: function (value) {
                setStore(value);
            } }, storeList.map(function (item, index) {
            return (React.createElement(Option, { className: "order-option", key: index, value: item.id }, item.name));
        })),
        React.createElement(Input, { value: platformDaySn || '', placeholder: "\u8BA2\u5355\u6D41\u6C34\u53F7", onChange: function (e) {
                var _a;
                setPlatformDaySn(((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) || '');
            } }),
        React.createElement(Input, { placeholder: "\u624B\u673A\u53F7\u540E\u56DB\u4F4D", value: phone || '', onChange: function (e) {
                var _a;
                setphone(((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) || '');
            } }),
        React.createElement(Button, { onClick: onSubmit, className: "orderbuttonactives" }, "\u641C\u7D22")));
    function onSubmit() {
        props.onSubmit({
            storeId: selectStore === initData.id ? null : selectStore,
            platformDaySn: platformDaySn,
            phone: phone
        });
    }
};
export default OrderSearchHeader;
var templateObject_1;
