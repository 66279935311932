var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { message, Select } from 'antd';
import { debounce } from 'lodash';
import qs from 'querystring';
import styled from 'styled-components';
import ajax from '@utils/ajax';
var Option = Select.Option;
var SelectBox = styled.div.withConfig({ displayName: "SelectBox", componentId: "sc-9jycpo" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ant-select-selection-selected-value {\n    height: 30px;\n  }\n"], ["\n  .ant-select-selection-selected-value {\n    height: 30px;\n  }\n"])));
var subAddress = {
    fontSize: '10px',
    color: '#999999',
    fontWeight: 400,
};
var PlaceGeoSearch = function (props, ref) {
    var _a = __read(React.useState([]), 2), placeSuggestions = _a[0], setPlaceSuggestions = _a[1];
    var selectRef = useRef();
    var getPlaceSuggestion = function (_a) {
        var query = _a.query, region = _a.region;
        var queryStr = qs.stringify({
            query: query,
            region: region,
            city_limit: true,
            output: 'json',
            ak: '9dN1HgehwG77WrCeHTAUbvi9GdspAywE'
        });
        return ajax.get("/city/positioning/baidu?" + queryStr);
    };
    var getPlaceByUid = function (uid) {
        return placeSuggestions.filter(function (place) { return place.uid === uid; })[0];
    };
    var handleAddressSearch = function (query) {
        var region = props.region;
        if (!query)
            return false;
        if (!region) {
            props.errorMessage && props.errorMessage();
            return false;
        }
        getPlaceSuggestion({ query: query, region: region }).then(function (_a) {
            var data = _a.data;
            if (data.result) {
                setPlaceSuggestions(data.result);
            }
            else {
                message.error(data.message);
            }
        });
    };
    var handleAddressChange = function (uid) {
        if (!uid) {
            props.onChange({
                location: {
                    lat: 0,
                    lng: 0
                },
                name: ''
            });
            return false;
        }
        var place = getPlaceByUid(uid);
        props.onChange({
            location: place.location,
            name: place.name
        });
    };
    useImperativeHandle(ref, function () {
        return {
            handleAddressSearch: handleAddressSearch,
            selectRef: selectRef,
        };
    });
    return (React.createElement(SelectBox, null,
        React.createElement(Select, { showSearch: true, defaultActiveFirstOption: false, showArrow: false, filterOption: false, onSearch: debounce(handleAddressSearch, 1000), onChange: handleAddressChange, notFoundContent: null, placeholder: "\u8BF7\u8F93\u5165\u8BE6\u7EC6\u5730\u5740\uFF0C\u5982\uFF1A\u8F6F\u4EF6\u56ED\u4E8C\u671F63\u53F7\u56DB\u5C42", ref: selectRef, id: "searchAddressDom" }, placeSuggestions && placeSuggestions.map(function (place) {
            return (React.createElement(Option, { key: place.uid, value: place.uid },
                React.createElement("div", null, place.name),
                React.createElement("div", { style: subAddress }, place.address)));
        }))));
};
export default forwardRef(PlaceGeoSearch);
var templateObject_1;
