var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, message, Popconfirm, Table } from 'antd';
import produce from 'immer';
import { isEqual } from 'lodash';
import qs from 'qs';
import styled from 'styled-components';
import { requestForLocation } from '@utils/request';
import FormModal from '@components/FormModal';
import ajax from '@utils/ajax';
import { getDeliveryOAuthUri } from '@utils/bindDelivery';
import { DELIVERY_PLATFORM_MAP } from '@utils/constants';
import { BindOrderPlatformEvents } from '../BindOrderPlatform/event';
import BindForm, { BindShansongMethod } from './BindForm';
import UUPTForm from './UUPTForm';
import EditServiceModal from './EditServiceModal';
import events from '@utils/events';
import { userInfo } from '@utils/localStorage';
import { sendPv } from '@/utils/umeng';
var getSwitchStatus = function (value) { return (Number(value) ? '开启' : '关闭'); };
var getDeliveryPlatformUnbindTips = function (storeName, platformName) {
    return "\u786E\u5B9A\u89E3\u9664" + storeName + "\u95E8\u5E97\u4E0E" + platformName + "\u7684\u7ED1\u5B9A\u5173\u7CFB\uFF1F\u89E3\u9664\u540E" + storeName + "\u95E8\u5E97\u5C06\u65E0\u6CD5\u4F7F\u7528" + platformName + "\u5E73\u53F0\u8FDB\u884C\u914D\u9001\uFF01";
};
var BindDeliveryTable = styled(Table).withConfig({ displayName: "BindDeliveryTable", componentId: "sc-p9alme" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 20px;\n"], ["\n  margin-top: 20px;\n"])));
var BindDeliveryPlatform = /** @class */ (function (_super) {
    __extends(BindDeliveryPlatform, _super);
    function BindDeliveryPlatform() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            bindModalVisible: false,
            uuptModalVisible: false,
            editService: {
                visible: false,
                initialValue: {}
            },
            yisongStoreId: ''
        };
        _this.checkBindDoneTimer = 0;
        _this.columns = [
            {
                title: '配送平台',
                dataIndex: 'platformName',
                key: 'platformName'
            },
            {
                title: '平台门店名称',
                dataIndex: 'store.platformStoreName',
                key: 'platformStoreName'
            },
            {
                title: '平台门店编号',
                // dataIndex: 'platformStoreId',
                render: function (item) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", null,
                            "\u95E8\u5E97id\uFF1A",
                            item.store.platformStoreId),
                        React.createElement("div", null,
                            "\u5546\u6237id\uFF1A",
                            item.store.platformShopId)));
                }
            },
            {
                title: '绑定状态',
                dataIndex: 'bindingStatus',
                key: 'bindingStatus'
            },
            // {
            //   title: '配送服务',
            //   key: 'service',
            //   render: (text: any, record: IDeliveryPlatform) => {
            //     const { platformType, platformPrivate } = record
            //     switch (platformType) {
            //       case DELIVERY_PLATFORM_MAP.SHANSONG:
            //         return (
            //           <div>
            //             <span>取件码{getSwitchStatus(platformPrivate.takeCode)}，
            //             收件码{getSwitchStatus(platformPrivate.receiveCode)}</span>
            //             <Icon type="edit" onClick={() => this.toggleEditService(record)} />
            //           </div>
            //         )
            //       default:
            //         return ''
            //     }
            //   }
            // },
            {
                title: '门店名称',
                dataIndex: 'store.name',
                key: 'storeName'
            },
            {
                title: '门店编号',
                dataIndex: 'store.numbering',
                key: 'storeNo'
            },
            {
                title: '所在城市',
                dataIndex: 'store.city',
                key: 'storeCity'
            },
            {
                title: '门店地址',
                dataIndex: 'store.address',
                key: 'storeAddress'
            },
            {
                title: '操作',
                key: 'action',
                render: function (text, item) {
                    return (React.createElement(Popconfirm, { title: getDeliveryPlatformUnbindTips(item.store.name, item.platformName), onConfirm: function () { return _this.handleDelete(item.id, item.platformType, item); }, overlayStyle: { maxWidth: '280px' }, cancelText: "\u53D6\u6D88", okText: "\u786E\u5B9A" },
                        React.createElement(Button, { size: "small" }, "\u89E3\u7ED1")));
                }
            }
        ];
        _this.handleDelete = function (id, platform, item) {
            if (platform === DELIVERY_PLATFORM_MAP.SHUNFENG) {
                ajax
                    .post("/deliver/shunfeng/unbinding", {
                    storeId: (item === null || item === void 0 ? void 0 : item.store.id) || '',
                })
                    .then(function (back) {
                    if (back && back.code === 0) {
                        _this.props.getDeliveryPlatform({});
                        message.success('配送平台删除成功');
                    }
                    else {
                        message.error(back.msg);
                    }
                });
            }
            else {
                _this.props.deleteDeliveryPlatformItem(id).then(function () {
                    _this.props.getDeliveryPlatform({});
                    message.success('配送平台删除成功');
                });
            }
        };
        _this.toggleDeliveryBind = function () {
            _this.setState(produce(function (draft) {
                draft.bindModalVisible = !_this.state.bindModalVisible;
            }));
        };
        _this.toggleUuptBind = function () {
            _this.setState(produce(function (draft) {
                draft.uuptModalVisible = !_this.state.uuptModalVisible;
            }));
        };
        _this.toggleEditService = function (initialValue) {
            if (initialValue === void 0) { initialValue = {}; }
            var editService = _this.state.editService;
            _this.setState(produce(function (draft) {
                draft.editService = {
                    initialValue: initialValue,
                    visible: !editService.visible
                };
            }));
        };
        _this.openNewTab = function (url) {
            if (_this.OAuthTab == null || _this.OAuthTab.closed) {
                _this.OAuthTab = window.open(url);
            }
            else {
                _this.OAuthTab.focus();
            }
            _this.checkBindDoneTimer = window.setInterval(function () {
                if (_this.OAuthTab && _this.OAuthTab.closed) {
                    _this.props.getDeliveryPlatform({});
                    clearInterval(_this.checkBindDoneTimer);
                }
            }, 1000);
        };
        _this.submitBindDelivery = function () {
            var editBindForm = _this.editBindForm.props.form;
            var _a = _this.editBindForm.state, redirectUri = _a.redirectUri, platformList = _a.platformList;
            var user = _this.props.user;
            var getBindedStores = function (type, values, storeId) {
                var masterStoreId = values.masterStoreId;
                ajax
                    .post("/distri/platform/complex/" + type, {
                    masterStoreId: masterStoreId,
                    slaveStoreId: storeId
                })
                    .then(function () {
                    message.success('绑定配送平台成功');
                    _this.props.getDeliveryPlatform({});
                    _this.toggleDeliveryBind();
                });
            };
            editBindForm.validateFields(function (errors, values) {
                if (errors)
                    return false;
                var storeId = values.storeId, platform = values.platform, receiveCode = values.receiveCode, takeCode = values.takeCode;
                var isOldShansong = platform === DELIVERY_PLATFORM_MAP.SHANSONG &&
                    values.method === BindShansongMethod.Old;
                var isOldDada = platform === DELIVERY_PLATFORM_MAP.DADA &&
                    values.method === BindShansongMethod.Old;
                var isOldFengNiao = platform === DELIVERY_PLATFORM_MAP.FengNiao &&
                    values.method === BindShansongMethod.Old;
                var isOldUUPT = platform === DELIVERY_PLATFORM_MAP.UUPT &&
                    values.method === BindShansongMethod.Old;
                var isOldMTPU = platform === DELIVERY_PLATFORM_MAP.MTPT &&
                    values.method === BindShansongMethod.Old;
                // 选择已经绑定过的闪送账号
                if (isOldShansong) {
                    getBindedStores('shansong', values, storeId);
                }
                else if (isOldDada) {
                    // 选择绑定过达达的账号
                    getBindedStores('dada', values, storeId);
                }
                else if (isOldFengNiao) {
                    getBindedStores('fengniao', values, storeId);
                }
                else if (isOldUUPT) {
                    getBindedStores('uupt', values, storeId);
                }
                else if (isOldMTPU) {
                    getBindedStores('meituan', values, storeId);
                }
                else if (platform === DELIVERY_PLATFORM_MAP.SHANSONG) {
                    //新绑定闪送
                    var platformData = platformList.filter(function (_a) {
                        var platform = _a.platform;
                        return platform === values.platform;
                    });
                    var authInfo = platformData[0].privateData;
                    var OAuthUri = getDeliveryOAuthUri({
                        redirectUri: redirectUri,
                        platform: platform,
                        authInfo: authInfo,
                        thirdStoreId: storeId,
                        state: {
                            storeId: storeId,
                            receiveCode: receiveCode,
                            takeCode: takeCode,
                            user: user.token
                        }
                    });
                    _this.toggleDeliveryBind();
                    _this.openNewTab(OAuthUri);
                }
                else if (platform === DELIVERY_PLATFORM_MAP.DADA) {
                    //新绑定达达
                    ajax
                        .get("/distri/platform/dada/auth/url?storeId=" + storeId)
                        .then(function (res) {
                        _this.toggleDeliveryBind();
                        _this.openNewTab(res.data.url);
                    });
                }
                else if (platform === DELIVERY_PLATFORM_MAP.SHUNFENG) {
                    // Modal.info({
                    //   title: '绑定顺丰',
                    //   content:
                    //     '绑定顺丰请联系电话13141259516，或发邮件至xin.yan@iyisong.net',
                    //   centered: true
                    // })
                    ajax
                        .post("/deliver/shunfeng/binding", {
                        storeId: storeId,
                        shunfengShopId: values.shunfengStoreId,
                    })
                        .then(function (back) {
                        if (back && back.code === 0) {
                            _this.toggleDeliveryBind();
                            _this.props.getDeliveryPlatform({});
                        }
                        else {
                            message.error(back.msg);
                        }
                    });
                }
                else if (platform === DELIVERY_PLATFORM_MAP.FengNiao) {
                    // 新绑定蜂鸟
                    var platformData = platformList.filter(function (_a) {
                        var platform = _a.platform;
                        return platform === values.platform;
                    });
                    _this.setState({
                        yisongStoreId: values.storeId
                    });
                    var authInfo = platformData[0].privateData;
                    _this.openNewTab(authInfo.authUrl);
                }
                else if (platform === DELIVERY_PLATFORM_MAP.UUPT) {
                    // 新绑定UU跑腿
                    _this.setState({
                        yisongStoreId: values.storeId
                    });
                    _this.toggleDeliveryBind();
                    _this.toggleUuptBind();
                }
                else if (platform === DELIVERY_PLATFORM_MAP.MTPT) {
                    // 新绑定美团跑腿
                    requestForLocation
                        .post('/api/meituan/auth', "ysStoreId=" + storeId)
                        .then(function (back) {
                        if (back.code === 0) {
                            _this.toggleDeliveryBind();
                            _this.props.getDeliveryPlatform({});
                            message.success('美团跑腿授权成功');
                        }
                        else if (back.code === 302 && back.data) {
                            _this.toggleDeliveryBind();
                            _this.openNewTab(back.data);
                        }
                        else {
                            message.error(back.msg);
                        }
                    });
                }
            });
        };
        _this.handleTableChange = function (pagination) {
            _this.props.getDeliveryPlatform({
                page: pagination.current,
                pageSize: pagination.pageSize
            });
        };
        return _this;
    }
    BindDeliveryPlatform.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    };
    BindDeliveryPlatform.prototype.componentDidMount = function () {
        var _this = this;
        this.props.getDeliveryPlatform({});
        events.on('fengToken', function (data) {
            var fengniaoMerchantId = data.fengniaoMerchantId, yisongMerchantId = data.yisongMerchantId;
            var params = {
                fengniaoMerchantId: fengniaoMerchantId,
                yisongMerchantId: yisongMerchantId,
                token: userInfo.token,
                yisongStoreId: _this.state.yisongStoreId
            };
            // 关掉蜂鸟页面
            if (_this.OAuthTab && !_this.OAuthTab.closed) {
                _this.OAuthTab.close();
            }
            // 再打开绑定蜂鸟门店的页面
            _this.openNewTab("/bind/delivery/fengniao?" + qs.stringify(params));
        });
        events.on('bindFengniaoSuccess', function () {
            _this.props.getDeliveryPlatform({});
            if (_this.OAuthTab && !_this.OAuthTab.closed) {
                _this.OAuthTab.close();
            }
        });
        BindOrderPlatformEvents.onSuccess(function () {
            _this.props.getDeliveryPlatform({});
            if (_this.OAuthTab && !_this.OAuthTab.closed) {
                _this.OAuthTab.close();
            }
        });
        sendPv();
    };
    BindDeliveryPlatform.prototype.componentWillUnmount = function () {
        clearInterval(this.checkBindDoneTimer);
    };
    BindDeliveryPlatform.prototype.render = function () {
        var _this = this;
        var state = this.state;
        var props = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { type: "primary", onClick: function () { return _this.toggleDeliveryBind(); } }, "\u7ED1\u5B9A\u914D\u9001\u5E73\u53F0"),
            React.createElement(FormModal, { title: "\u7ED1\u5B9A\u914D\u9001\u5E73\u53F0", visible: state.bindModalVisible, onCancel: function () { return _this.toggleDeliveryBind(); }, onOk: this.submitBindDelivery, okText: "\u4E0B\u4E00\u6B65", destroyOnClose: true },
                React.createElement(BindForm, { wrappedComponentRef: function (form) { return (_this.editBindForm = form); } })),
            React.createElement(UUPTForm, { visible: state.uuptModalVisible, onCancel: function () { return _this.toggleUuptBind(); }, ysStoreId: state.yisongStoreId, getDeliveryPlatform: props.getDeliveryPlatform }),
            React.createElement(EditServiceModal, { visible: state.editService.visible, initialValue: state.editService.initialValue, toggleVisible: this.toggleEditService }),
            React.createElement(BindDeliveryTable, { columns: this.columns, dataSource: props.dataSource, pagination: props.pagination, loading: props.listLoading, onChange: this.handleTableChange, rowKey: "id", bordered: true })));
    };
    return BindDeliveryPlatform;
}(React.Component));
var mapState = function (state) {
    var _a = state.deliveryPlatforms, data = _a.data, loading = _a.loading;
    return {
        dataSource: data.list,
        pagination: {
            current: data.page,
            pageSize: data.pageSize,
            total: data.total
        },
        listLoading: loading,
        user: state.user
    };
};
var mapDispatch = function (dispatch) {
    var deliveryPlatforms = dispatch.deliveryPlatforms;
    return {
        getDeliveryPlatform: deliveryPlatforms.fetchData,
        deleteDeliveryPlatformItem: deliveryPlatforms.deleteItemWithReq
    };
};
export default connect(mapState, mapDispatch)(BindDeliveryPlatform);
var templateObject_1;
