/*
 * @Author: hijack
 * @Date: 2021-06-18 14:29:26
 * @LastEditTime: 2021-07-08 16:36:08
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/container/Orders/components/SendDeliveryTab.tsx
 */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import styled from 'styled-components';
import { EDeliveryType } from '../constants';
import { getgeneralNumberCheck } from '@/apis/generalNumber';
import produce from 'immer';
var SPlatformContainer = styled.div.withConfig({ displayName: "SPlatformContainer", componentId: "sc-1mlastq" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  padding: 0;\n  justify-content: flex-start;\n  margin-bottom: 25px;\n  cursor: pointer;\n  .text {\n    font-size: 16px;\n    font-family: PingFangSC-Regular, PingFang SC;\n    font-weight: 400;\n    color: #999999;\n    line-height: 22px;\n    margin-right: 25px;\n  }\n  .active {\n    font-size: 16px;\n    font-family: PingFangSC-Medium, PingFang SC;\n    font-weight: 500;\n    color: #333333;\n    line-height: 22px;\n    position: relative;\n    &::after {\n      content: '';\n      position: absolute;\n      width: 26px;\n      height: 3px;\n      background: #14d8bb;\n      border-radius: 3px;\n      bottom: -5px;\n      left: 50%;\n      transform: translate(-50%);\n    }\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  padding: 0;\n  justify-content: flex-start;\n  margin-bottom: 25px;\n  cursor: pointer;\n  .text {\n    font-size: 16px;\n    font-family: PingFangSC-Regular, PingFang SC;\n    font-weight: 400;\n    color: #999999;\n    line-height: 22px;\n    margin-right: 25px;\n  }\n  .active {\n    font-size: 16px;\n    font-family: PingFangSC-Medium, PingFang SC;\n    font-weight: 500;\n    color: #333333;\n    line-height: 22px;\n    position: relative;\n    &::after {\n      content: '';\n      position: absolute;\n      width: 26px;\n      height: 3px;\n      background: #14d8bb;\n      border-radius: 3px;\n      bottom: -5px;\n      left: 50%;\n      transform: translate(-50%);\n    }\n  }\n"])));
var SendDeliveryTab = /** @class */ (function (_super) {
    __extends(SendDeliveryTab, _super);
    function SendDeliveryTab() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            payAccountTypes: [EDeliveryType.Channel]
        };
        return _this;
    }
    SendDeliveryTab.prototype.componentDidMount = function () {
        var _this = this;
        getgeneralNumberCheck().then(function (res) {
            _this.setState(produce(function (draft) {
                draft.payAccountTypes = res
                    ? _this.props.payAccountTypes
                    : [EDeliveryType.Channel];
            }));
        });
    };
    SendDeliveryTab.prototype.render = function () {
        var _a = this.props, currentTab = _a.currentTab, handleSwitchTab = _a.handleSwitchTab, oneByMoreData = _a.oneByMoreData;
        var payAccountTypes = oneByMoreData && oneByMoreData.length > 1
            ? [EDeliveryType.Platform]
            : this.state.payAccountTypes;
        return (React.createElement(SPlatformContainer, null, payAccountTypes &&
            payAccountTypes.length > 0 &&
            payAccountTypes.map(function (item) { return (React.createElement("div", { className: "text " + (currentTab === item && 'active'), onClick: handleSwitchTab(item), key: item }, item === EDeliveryType.Channel ? '自有账号' : '通用号')); })));
    };
    return SendDeliveryTab;
}(React.PureComponent));
export default SendDeliveryTab;
var templateObject_1;
