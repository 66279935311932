var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import * as React from 'react';
import styled from 'styled-components';
import { CancelOrderStatus, RetreatOrderStatus } from '@models';
import { AgreeCancelAction, AgreeRefundAction, DisagreeCancelAction, DisagreeRefundAction, ReplyUrgedAction, ViewDeliveryAction, PickBySelfAction, PrintAction, AgreeRefundActionDONE, AgreeRefundActionIN_THE_DISTRIBUTION, AgreeCancelActionIN_THE_DISTRIBUTION, CancelOrderAction, IgnoreAction, SendDeliveryAction, AppendTipAction, CancelDeliveryOrderActionWAIT_GRAB, CancelDeliveryOrderAction, MoreAction } from './action';
import { ORDER_STATUS_KEYS_MAP, ORDER_STATUS_MAP, ORDER_SUB_STATUS_MAP } from './constants';
import { ActionsContext, OrderContext } from './context';
// import {
//   orderActionsMapMeituan,
//   orderActionsMapDefault
// } from './orderActionsMap'
import lodash from 'lodash';
import { getOrderActionsMap, isSendSelf } from './orderActionsMap';
var OrderActionsWrapper = styled.div.withConfig({ displayName: "OrderActionsWrapper", componentId: "sc-1rqqmyf" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  bottom: 0px;\n  padding: 0px;\n  background-color: #fff;\n  display: flex;\n"], ["\n  bottom: 0px;\n  padding: 0px;\n  background-color: #fff;\n  display: flex;\n"])));
var BaseActionsWrapper = styled.div.withConfig({ displayName: "BaseActionsWrapper", componentId: "sc-1uvk30z" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  border-radius: 22px;\n  margin-bottom: 5px;\n  width: 100%;\n  > .ant-btn {\n    margin-right: 10px;\n    margin-bottom: 10px;\n    padding: 0 1%;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  border-radius: 22px;\n  margin-bottom: 5px;\n  width: 100%;\n  > .ant-btn {\n    margin-right: 10px;\n    margin-bottom: 10px;\n    padding: 0 1%;\n  }\n"])));
var OtherActionsWrapper = styled.div.withConfig({ displayName: "OtherActionsWrapper", componentId: "sc-144tsiu" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n  > .ant-btn {\n    margin-right: 10px;\n    margin-bottom: 10px;\n    padding: 0 1%;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n  > .ant-btn {\n    margin-right: 10px;\n    margin-bottom: 10px;\n    padding: 0 1%;\n  }\n"])));
export var renderAction = function (actions, tabStatus, orderEvent, item) {
    var value = __assign({ tabStatus: tabStatus,
        orderEvent: orderEvent }, item);
    var mapactions = actions.filter(function (item) { return item !== null; });
    function PrintAuth(action) {
        var acName = '订单处理';
        // for(let key in buttonAuthFunc){
        //   if(buttonAuthFunc[key].indexOf(action?.name)!==-1){
        //     acName= key
        //     break;
        //   }
        // }
        return acName;
    }
    return (React.createElement(ActionsContext.Provider, { value: value }, mapactions.map(function (Action, key) {
        // return (getAuthDom(PrintAuth(Action),<Action key={key} listObject={item}/>))
        return React.createElement(Action, { key: key, listObject: item });
    })));
};
var OrderActions = function (_a) {
    var _b;
    var item = _a.item;
    var _c = React.useContext(OrderContext), tabStatus = _c.tabStatus, orderEvent = _c.orderEvent;
    var orderActionsMap = getOrderActionsMap(item);
    var status = ORDER_STATUS_KEYS_MAP[item.status];
    var actions = orderActionsMap[status];
    var otherActions = [];
    if (actions) {
        if (tabStatus === ORDER_SUB_STATUS_MAP.URGE_APPLY) {
            if (item.status === 1) {
                //补打小票、忽略配送、配送发单、回复催单
                actions = [
                    item.platform !== 'manual' ? PrintAction : null,
                    IgnoreAction,
                    SendDeliveryAction,
                    ReplyUrgedAction
                ];
            }
            else if (item.status === 50) {
                //忽略配送、配送发单、回复催单
                actions = [
                    item.platform !== 'manual' ? PrintAction : null,
                    CancelDeliveryOrderActionWAIT_GRAB,
                    AppendTipAction,
                    ReplyUrgedAction
                ];
            }
            else if (item.status === 2) {
                //忽略配送、配送发单、回复催单
                actions = [
                    item.platform !== 'manual' ? PrintAction : null,
                    CancelDeliveryOrderAction,
                    ReplyUrgedAction
                ];
            }
        }
        if (tabStatus === ORDER_SUB_STATUS_MAP.DISTRIBUTION_ABNORMAL) {
            actions = [
                CancelOrderAction,
                item.platform !== 'manual' ? PrintAction : null,
                SendDeliveryAction,
                IgnoreAction
            ];
        }
        if (tabStatus === ORDER_SUB_STATUS_MAP.CANCELED_APPLY) {
            if (item.cancelOrder.status === CancelOrderStatus.WaitCheck) {
                if (item.status === 2) {
                    otherActions = [];
                    actions = [AgreeCancelActionIN_THE_DISTRIBUTION, DisagreeCancelAction];
                }
                else {
                    otherActions = [];
                    actions = [AgreeCancelAction, DisagreeCancelAction];
                }
            }
        }
        //参考 http://wiki.bingex.com/pages/viewpage.action?pageId=26088390
        if (tabStatus === ORDER_SUB_STATUS_MAP.REFUND_APPLY) {
            otherActions = [];
            if (item.retreatOrder.status === RetreatOrderStatus.WaitCheck) {
                if (item.status === 2) {
                    actions = [AgreeRefundActionIN_THE_DISTRIBUTION, DisagreeRefundAction];
                }
                else if (item.status === 3) {
                    actions = [AgreeRefundActionDONE, DisagreeRefundAction];
                }
                else {
                    actions = [AgreeRefundAction, DisagreeRefundAction];
                }
            }
        }
        if (tabStatus === ORDER_SUB_STATUS_MAP.DISTRIBUTION_ABNORMAL) {
            // actions = [AgreeCancelAction, DisagreeCancelAction]
        }
        // 如果是到店自取自取  selfFetch
        if (((_b = item.selfFetch) === null || _b === void 0 ? void 0 : _b.code) === 0 &&
            status === ORDER_STATUS_MAP.IN_THE_DISTRIBUTION) {
            actions = [
                item.platform !== 'manual' ? PrintAction : null,
                PickBySelfAction
            ];
        }
        //是待配送并且有过配送单
        if (isViewDeliveryAction(item)) {
            actions = __spread(actions, [ViewDeliveryAction]);
        }
        var deliverTimeWidth = item.deliverTime === '立即送达' ? 860 : 990;
        var width = window.innerWidth - deliverTimeWidth;
        var count = Math.floor(width / 110);
        var actionsnonull = actions.filter(function (item) { return item !== null; });
        var actionsdeepclone = lodash.cloneDeep(__spread(otherActions, actionsnonull));
        var len = actionsdeepclone.length;
        var countaction = __spread(otherActions, actionsnonull);
        if (count < len) {
            // countaction = actions.slice(0, count - 1)
            countaction = actionsdeepclone.slice(len - count + 1);
        }
        return (React.createElement(OrderActionsWrapper, { style: {
                background: item.reservationOrder !== 0 ? '#fff' : '#fef8f1'
            } },
            React.createElement(BaseActionsWrapper, null, renderAction(countaction, tabStatus, orderEvent, item)),
            getMore({
                action: actionsdeepclone.slice(0, len - count + 1),
                tabStatus: tabStatus,
                orderEvent: orderEvent,
                item: item
            }, count, len)));
    }
    return null;
    function getMore(props, count, len) {
        return count < len ? React.createElement(MoreAction, __assign({}, props)) : null;
    }
    //是否显示配送单
    function isViewDeliveryAction(item) {
        return (item.distriOrderId && item.distriOrderId.trim()) || isSendSelf(item);
    }
};
// @ts-ignore
export default OrderActions;
var templateObject_1, templateObject_2, templateObject_3;
