var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var OrderSummary = styled.div.withConfig({ displayName: "OrderSummary", componentId: "sc-1y9dlib" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0 0 20px;\n"], ["\n  margin: 0 0 20px;\n"])));
export var OrderLineChart = styled.div.withConfig({ displayName: "OrderLineChart", componentId: "sc-194htja" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0 0 20px;\n"], ["\n  margin: 0 0 20px;\n"])));
export var IncomeFormula = styled.div.withConfig({ displayName: "IncomeFormula", componentId: "sc-1a91fzq" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  padding: 50px 24px 55px;\n"], ["\n  display: flex;\n  padding: 50px 24px 55px;\n"])));
export var FormulaItem = styled.div.withConfig({ displayName: "FormulaItem", componentId: "sc-1ww61nz" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 160px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 160px;\n"])));
export var FormulaTitle = styled.h6.withConfig({ displayName: "FormulaTitle", componentId: "sc-fhksz8" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 0;\n  font-weight: normal;\n  font-size: 14px;\n  color: #999;\n"], ["\n  margin: 0;\n  font-weight: normal;\n  font-size: 14px;\n  color: #999;\n"])));
export var FormulaData = styled.div.withConfig({ displayName: "FormulaData", componentId: "sc-129gsh1" })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 17px 0 30px;\n"], ["\n  padding: 17px 0 30px;\n"])));
export var FormulaDataNumber = styled.span.withConfig({ displayName: "FormulaDataNumber", componentId: "sc-15mo1y3" })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 36px;\n  color: #01cccc;\n"], ["\n  font-size: 36px;\n  color: #01cccc;\n"])));
export var FormulaDataUnit = styled.span.withConfig({ displayName: "FormulaDataUnit", componentId: "sc-1k01bp5" })(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 21px;\n  color: #999;\n"], ["\n  font-size: 21px;\n  color: #999;\n"])));
export var FormulaItemOperator = styled.div.withConfig({ displayName: "FormulaItemOperator", componentId: "sc-10rvel3" })(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100px;\n"])));
export var FormulaOperator = styled.span.withConfig({ displayName: "FormulaOperator", componentId: "sc-b8z3h3" })(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: inline-block;\n  margin-top: 45px;\n  padding: 0 40px;\n  font-size: 24px;\n  font-weight: bold;\n  color: #d8d8d8;\n"], ["\n  display: inline-block;\n  margin-top: 45px;\n  padding: 0 40px;\n  font-size: 24px;\n  font-weight: bold;\n  color: #d8d8d8;\n"])));
export var FormulaCompareContainer = styled.div.withConfig({ displayName: "FormulaCompareContainer", componentId: "sc-bv3h5h" })(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  background: rgba(95, 183, 167, 0.05);\n  font-size: 14px;\n  color: #333;\n"], ["\n  background: rgba(95, 183, 167, 0.05);\n  font-size: 14px;\n  color: #333;\n"])));
export var FlatValue = styled.span.withConfig({ displayName: "FlatValue", componentId: "sc-1i5gsxw" })(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-left: 10px;\n"], ["\n  margin-left: 10px;\n"])));
export var IncreaseValue = styled.span.withConfig({ displayName: "IncreaseValue", componentId: "sc-p5muxa" })(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  margin-left: 10px;\n  position: relative;\n  padding-left: 10px;\n  color: #53c41b;\n  &::before {\n    content: ' ';\n    position: absolute;\n    top: 50%;\n    left: 0;\n    margin-top: -6px;\n    width: 0;\n    height: 0;\n    border: 4px solid transparent;\n    border-bottom: 4px solid #53c41b;\n  }\n"], ["\n  margin-left: 10px;\n  position: relative;\n  padding-left: 10px;\n  color: #53c41b;\n  &::before {\n    content: ' ';\n    position: absolute;\n    top: 50%;\n    left: 0;\n    margin-top: -6px;\n    width: 0;\n    height: 0;\n    border: 4px solid transparent;\n    border-bottom: 4px solid #53c41b;\n  }\n"])));
export var DecreaseValue = styled.span.withConfig({ displayName: "DecreaseValue", componentId: "sc-vw9vs" })(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  margin-left: 10px;\n  position: relative;\n  padding-left: 10px;\n  color: #f5222d;\n  &::before {\n    content: ' ';\n    position: absolute;\n    top: 50%;\n    left: 0;\n    margin-top: -2px;\n    width: 0;\n    height: 0;\n    border: 4px solid transparent;\n    border-top: 4px solid #f5222d;\n  }\n"], ["\n  margin-left: 10px;\n  position: relative;\n  padding-left: 10px;\n  color: #f5222d;\n  &::before {\n    content: ' ';\n    position: absolute;\n    top: 50%;\n    left: 0;\n    margin-top: -2px;\n    width: 0;\n    height: 0;\n    border: 4px solid transparent;\n    border-top: 4px solid #f5222d;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
