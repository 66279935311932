var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Layout, Menu } from 'antd';
import styled from 'styled-components';
import { Colors } from '@assets/styles/variables';
var Sider = Layout.Sider, Content = Layout.Content, Footer = Layout.Footer;
export var LogoWithText = styled.div.withConfig({ displayName: "LogoWithText", componentId: "sc-1on96b9" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 16px 20px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 16px 20px;\n"])));
export var Logo = styled.img.withConfig({ displayName: "Logo", componentId: "sc-1ykoy8u" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 80px;\n  /* height: 38px; */\n"], ["\n  width: 80px;\n  /* height: 38px; */\n"])));
export var LogoText = styled.span.withConfig({ displayName: "LogoText", componentId: "sc-1q75eep" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 4px;\n  font-size: 16px;\n  color: #fff;\n"], ["\n  margin-left: 4px;\n  font-size: 16px;\n  color: #fff;\n"])));
export var DefaultSider = styled(Sider).withConfig({ displayName: "DefaultSider", componentId: "sc-1j1jdc9" })(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var DefaultMenu = styled(Menu).withConfig({ displayName: "DefaultMenu", componentId: "sc-6875ds" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  &&& .ant-menu-item-selected {\n    position: relative;\n    background: rgba(95, 183, 167, 0.1);\n    color: #01cccc;\n\n    &::before {\n      content: '';\n      position: absolute;\n      top: 0;\n      bottom: 0;\n      left: 0;\n      width: 4px;\n      height: 100%;\n      background: ", ";\n    }\n  }\n"], ["\n  &&& .ant-menu-item-selected {\n    position: relative;\n    background: rgba(95, 183, 167, 0.1);\n    color: #01cccc;\n\n    &::before {\n      content: '';\n      position: absolute;\n      top: 0;\n      bottom: 0;\n      left: 0;\n      width: 4px;\n      height: 100%;\n      background: ", ";\n    }\n  }\n"])), Colors.Green95);
export var DefaultMenuItem = styled(Menu.Item).withConfig({ displayName: "DefaultMenuItem", componentId: "sc-13zh59k" })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  &&& {\n    padding-left: 20px;\n    height: 48px;\n    color: #9aa6bb;\n    font-size: 14px;\n    line-height: 48px;\n    cursor: pointer;\n    transition: none;\n  }\n"], ["\n  &&& {\n    padding-left: 20px;\n    height: 48px;\n    color: #9aa6bb;\n    font-size: 14px;\n    line-height: 48px;\n    cursor: pointer;\n    transition: none;\n  }\n"])));
export var DefaultFooter = styled(Footer).withConfig({ displayName: "DefaultFooter", componentId: "sc-7edrdj" })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  && {\n    padding: 17px 0;\n    background: rgba(216, 216, 216, 0.1);\n    font-size: 14px;\n    color: #7d8188;\n    text-align: center;\n  }\n"], ["\n  && {\n    padding: 17px 0;\n    background: rgba(216, 216, 216, 0.1);\n    font-size: 14px;\n    color: #7d8188;\n    text-align: center;\n  }\n"])));
export var DefaultContent = styled(Content).withConfig({ displayName: "DefaultContent", componentId: "sc-8t50ui" })(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  && {\n    flex: auto;\n    overflow-y: auto;\n    /* margin-top: 10px; */\n    min-height: 0;\n    background: rgba(246, 248, 251, 1);\n    padding: 20px;\n    box-sizing: border-box;\n  }\n"], ["\n  && {\n    flex: auto;\n    overflow-y: auto;\n    /* margin-top: 10px; */\n    min-height: 0;\n    background: rgba(246, 248, 251, 1);\n    padding: 20px;\n    box-sizing: border-box;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
