var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { Component, createRef } from 'react';
import { Button, Col, Form, Input, message, Row, Select } from 'antd';
import produce from 'immer';
import debounce from 'lodash/debounce';
import qs from 'querystring';
import styled from 'styled-components';
import CitySelect from '@components/CitySelect';
import GeoRevise from '@components/GeoRevise';
import UploadImage from '@components/UploadImage';
import ajax from '@utils/ajax';
var createFormField = Form.createFormField;
var FormItem = Form.Item;
var Option = Select.Option;
var formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 }
};
// const phoneRegex = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/
var phoneRegex = /^1[1-9]\d{9}$/;
var getGeoByAddress = function (address) {
    var geo = new window.BMap.Geocoder();
    return new Promise(function (resolve, reject) {
        geo.getPoint(address, function (point) {
            if (point) {
                return resolve(point);
            }
        });
    });
};
var getStoreTypeDict = function () {
    return ajax.get('/dictionary/list?group=store');
};
var getCitySelectInitialValue = function (city) {
    if (!city) {
        return {
            province: '',
            region: '',
            county: ''
        };
    }
    var cityCodes = JSON.parse(city);
    return {
        province: cityCodes[0],
        region: cityCodes[1],
        county: cityCodes[2]
    };
};
export var getPlaceSuggestion = function (_a) {
    var query = _a.query, region = _a.region;
    var queryStr = qs.stringify({
        query: query,
        region: region,
        city_limit: true,
        output: 'json',
        ak: '9dN1HgehwG77WrCeHTAUbvi9GdspAywE'
    });
    return ajax.get("/city/positioning/baidu?" + queryStr);
};
var AddressInputItem = styled.div.withConfig({ displayName: "AddressInputItem", componentId: "sc-2a08lh" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: -12px;\n  left: 360px;\n"], ["\n  position: absolute;\n  top: -12px;\n  left: 360px;\n"])));
var GeoItem = styled.p.withConfig({ displayName: "GeoItem", componentId: "sc-spw24a" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-sizing: border-box;\n  padding: 7px 12px;\n  border-radius: 3px;\n  background: #f4f4f5;\n  font-size: 14px;\n  line-height: 20px;\n"], ["\n  box-sizing: border-box;\n  padding: 7px 12px;\n  border-radius: 3px;\n  background: #f4f4f5;\n  font-size: 14px;\n  line-height: 20px;\n"])));
var Tips = styled.p.withConfig({ displayName: "Tips", componentId: "sc-18tcytv" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0;\n  color: #ccc;\n  font-size: 12px;\n"], ["\n  margin: 0;\n  color: #ccc;\n  font-size: 12px;\n"])));
var MultiLineTips = styled.p.withConfig({ displayName: "MultiLineTips", componentId: "sc-5t1dng" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0;\n  color: #ccc;\n  font-size: 12px;\n  line-height: initial;\n"], ["\n  margin: 0;\n  color: #ccc;\n  font-size: 12px;\n  line-height: initial;\n"])));
var SFormBox = styled.div.withConfig({ displayName: "SFormBox", componentId: "sc-187z16p" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  .ant-form-item-label {\n    white-space: normal;\n  }\n"], ["\n  .ant-form-item-label {\n    white-space: normal;\n  }\n"])));
var SCardBox = styled.div.withConfig({ displayName: "SCardBox", componentId: "sc-owq664" })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: ", ";\n  .ant-upload.ant-upload-select-picture-card{\n    margin-bottom: 0;\n  }\n  .ant-form-item-control {\n    line-height: 0;\n  }\n  .ant-form-item {\n    margin-bottom: 0;\n  }\n"], ["\n  display: flex;\n  margin-bottom: ", ";\n  .ant-upload.ant-upload-select-picture-card{\n    margin-bottom: 0;\n  }\n  .ant-form-item-control {\n    line-height: 0;\n  }\n  .ant-form-item {\n    margin-bottom: 0;\n  }\n"])), function (props) { return props.marginBottom ? props.marginBottom : 0; });
var SPlatformStatus = styled.div.withConfig({ displayName: "SPlatformStatus", componentId: "sc-1t8lsdl" })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  .title {\n    color: rgba(0, 0, 0, 0.85);\n    font-size: 14px;\n    padding-bottom: 15px;\n  }\n  .item {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    height: 34px;\n    background: #F5F6F7;\n    padding: 0 20px;\n    box-sizing: border-box;\n    span {\n      padding-left: 20px;\n    }\n    .text1 {\n      font-size: 14px;\n      color: #BBBBBB;\n    }\n    .text2 {\n      font-size: 14px;\n      color: #FF0300;\n    }\n    .text3 {\n      font-size: 14px;\n      color: #35BAA7;\n      cursor: pointer;\n    }\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  .title {\n    color: rgba(0, 0, 0, 0.85);\n    font-size: 14px;\n    padding-bottom: 15px;\n  }\n  .item {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    height: 34px;\n    background: #F5F6F7;\n    padding: 0 20px;\n    box-sizing: border-box;\n    span {\n      padding-left: 20px;\n    }\n    .text1 {\n      font-size: 14px;\n      color: #BBBBBB;\n    }\n    .text2 {\n      font-size: 14px;\n      color: #FF0300;\n    }\n    .text3 {\n      font-size: 14px;\n      color: #35BAA7;\n      cursor: pointer;\n    }\n  }\n"])));
var EditStoreForm = /** @class */ (function (_super) {
    __extends(EditStoreForm, _super);
    function EditStoreForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            geo: {
                lng: 0,
                lat: 0 // 纬度
            },
            address: '',
            geoReviseVisible: false,
            storeTypeDict: [],
            placeSuggestions: [],
            locationText: ''
        };
        _this.citySelect = createRef();
        _this.handleAddressChange = function (uid) {
            if (!uid) {
                _this.setState(produce(function (draft) {
                    draft.geo.lng = 0;
                    draft.geo.lat = 0;
                }));
                return false;
            }
            var place = _this.getPlaceByUid(uid);
            _this.setState(produce(function (draft) {
                draft.geo = place.location;
                draft.address = place.name;
            }));
        };
        _this.handleAddressSearch = function (query) {
            if (!query)
                return false;
            var region = _this.citySelect.current.getRegion();
            var locationText = _this.citySelect.current.getLocationText();
            if (!region) {
                message.info('请先选择所在城市');
                return false;
            }
            getPlaceSuggestion({ query: query, region: region }).then(function (_a) {
                var data = _a.data;
                var placeSuggestions = data.result;
                if (placeSuggestions) {
                    _this.setState(produce(function (draft) {
                        draft.placeSuggestions = placeSuggestions,
                            draft.locationText = locationText;
                    }));
                }
                else {
                    message.error(data.message);
                }
            });
        };
        _this.getPlaceByUid = function (uid) {
            return _this.state.placeSuggestions.filter(function (place) { return place.uid === uid; })[0];
        };
        _this.toggleGeoRevise = function () {
            _this.setState(produce(function (draft) {
                draft.geoReviseVisible = !_this.state.geoReviseVisible;
            }));
        };
        _this.checkCity = function (rule, value, callback) {
            if (value.county) {
                return callback();
            }
            callback('请选择所在城市');
        };
        return _this;
    }
    EditStoreForm.prototype.componentWillMount = function () {
        var _this = this;
        var latitudeLongitude = this.props.initialValue.latitudeLongitude;
        if (latitudeLongitude) {
            var _a = __read(latitudeLongitude.split(','), 2), lng_1 = _a[0], lat_1 = _a[1];
            this.setState(produce(function (draft) {
                draft.geo.lng = Number(lng_1);
                draft.geo.lat = Number(lat_1);
            }));
        }
        getStoreTypeDict().then(function (_a) {
            var storeTypeDict = _a.data;
            _this.setState(produce(function (draft) {
                draft.storeTypeDict = storeTypeDict;
            }));
        });
    };
    EditStoreForm.prototype.render = function () {
        var _this = this;
        var getFieldDecorator = this.props.form.getFieldDecorator;
        var _a = this.props, _b = _a.initialValue, cityCodes = _b.cityCodes, numbering = _b.numbering, partnerStoreList = _b.partnerStoreList, isEditMode = _a.isEditMode, handleSubmit = _a.handleSubmit;
        var state = this.state;
        return (React.createElement(SFormBox, null,
            React.createElement(Form, __assign({}, formItemLayout),
                React.createElement(FormItem, { label: "\u95E8\u5E97\u540D\u79F0" }, getFieldDecorator('name', {
                    rules: [
                        {
                            required: true,
                            message: '请输入门店名称'
                        }
                    ]
                })(React.createElement(Input, { placeholder: "" }))),
                numbering ? (React.createElement(FormItem, { label: "\u95E8\u5E97\u7F16\u53F7" }, getFieldDecorator('numbering', {})(React.createElement(Input, { placeholder: "", disabled: true })))) : null,
                React.createElement(FormItem, { label: "\u6240\u5728\u57CE\u5E02" }, getFieldDecorator('city', {
                    initialValue: getCitySelectInitialValue(cityCodes),
                    rules: [
                        {
                            required: true,
                            validator: this.checkCity
                        }
                    ]
                })(React.createElement(CitySelect, { onChange: function () {
                        return _this.setState(produce(function (draft) {
                            draft.placeSuggestions = [];
                        }));
                    }, ref: this.citySelect }))),
                React.createElement(FormItem, { label: "\u95E8\u5E97\u5730\u5740" },
                    getFieldDecorator('address', {
                        rules: [
                            {
                                required: true,
                                message: '请输入门店地址'
                            }
                        ]
                    })(React.createElement(Select, { showSearch: true, defaultActiveFirstOption: false, showArrow: false, filterOption: false, onSearch: debounce(this.handleAddressSearch.bind(this), 1000), onChange: this.handleAddressChange, notFoundContent: null, placeholder: "\u8BF7\u8F93\u5165\u8BE6\u7EC6\u5730\u5740\uFF0C\u5982\uFF1A\u8F6F\u4EF6\u56ED\u4E8C\u671F63\u53F7\u56DB\u5C42" }, state.placeSuggestions && state.placeSuggestions.map(function (place) {
                        return (React.createElement(Option, { key: place.uid, value: place.uid }, place.name));
                    }))),
                    React.createElement(React.Fragment, null,
                        React.createElement(AddressInputItem, null,
                            React.createElement(Button, { type: "primary", onClick: this.toggleGeoRevise }, "\u5730\u56FE\u6821\u6B63"),
                            React.createElement(GeoRevise, { visible: state.geoReviseVisible, geoPoint: this.state.geo, onCancel: this.toggleGeoRevise, onOk: function (_a) {
                                    var lng = _a.lng, lat = _a.lat;
                                    return _this.setState(produce(function (draft) {
                                        draft.geo.lng = lng;
                                        draft.geo.lat = lat;
                                    }), function () {
                                        _this.toggleGeoRevise();
                                    });
                                } })),
                        React.createElement(GeoItem, null,
                            "\u7ECF\u5EA6: ",
                            state.geo.lng,
                            " \u00A0\u00A0\u00A0\u00A0 \u7EAC\u5EA6:",
                            ' ',
                            state.geo.lat))),
                React.createElement(FormItem, { label: "\u95E8\u724C\u53F7" }, getFieldDecorator('detailsAddress', {
                    rules: [
                        {
                            required: false,
                            message: '请输入门牌'
                        }
                    ]
                })(React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u8BE6\u7EC6\u697C\u5C42/\u95E8\u724C\u53F7\uFF0C\u5982\u4E8C\u5C42203\u5477\u54FA\u706B\u9505\u95E8\u53E3" }))),
                React.createElement(FormItem, { label: "\u8054\u7CFB\u7535\u8BDD" }, getFieldDecorator('phone', {
                    rules: [
                        {
                            required: true,
                            pattern: phoneRegex,
                            message: '请输入正确的手机号'
                        }
                    ]
                })(React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u8054\u7CFB\u7535\u8BDD\uFF0C\u5982\uFF1A13121734630" }))),
                React.createElement(FormItem, { label: "\u95E8\u5E97logo" },
                    React.createElement(Row, { gutter: 8 },
                        React.createElement(Col, { span: 8 }, getFieldDecorator('logoUrl', {})(React.createElement(UploadImage, null))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(MultiLineTips, null, "\u4EC5\u652F\u6301 jpg\uFF0Cpng \u683C\u5F0F\u7684\u56FE\u7247"),
                            React.createElement(MultiLineTips, null, "\u5206\u8FA8\u7387\u4E0D\u4F4E\u4E8E 140 * 140"),
                            React.createElement(MultiLineTips, null, "\u6587\u4EF6\u5927\u5C0F\u5728 2M \u4EE5\u5185")))),
                React.createElement(FormItem, { label: "\u4EA7\u54C1\u7C7B\u578B" },
                    React.createElement(Row, { gutter: 8 },
                        React.createElement(Col, { span: 8 }, getFieldDecorator('type', {
                            rules: [
                                {
                                    required: true,
                                    message: '请选择产品类型'
                                }
                            ]
                        })(React.createElement(Select, { placeholder: "\u4EA7\u54C1\u7C7B\u578B" }, state.storeTypeDict.map(function (type, key) {
                            return (React.createElement(Option, { key: key, value: type.code }, type.value));
                        })))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(Tips, null, "\u81EA\u52A8\u6D3E\u5355\u65F6\u4F1A\u540C\u6B65\u5230\u914D\u9001\u5355\u4E0A")))),
                React.createElement(FormItem, { label: "\u8D1F\u8D23\u4EBA\u59D3\u540D" }, getFieldDecorator('chargeName', {})(React.createElement(Input, { placeholder: "\u95E8\u5E97\u8D1F\u8D23\u4EBA\u59D3\u540D" }))),
                React.createElement(FormItem, { label: "\u8D1F\u8D23\u4EBA\u8EAB\u4EFD\u8BC1\u53F7" }, getFieldDecorator('chargeIdCardNumber', {})(React.createElement(Input, { placeholder: "\u8EAB\u4EFD\u8BC1\u53F7" }))),
                React.createElement(FormItem, { label: "\u7EDF\u4E00\u793E\u4F1A\u4FE1\u7528\u4EE3\u7801" }, getFieldDecorator('unifiedSocialCreditCode', {})(React.createElement(Input, { placeholder: "\u4FE1\u7528\u4EE3\u7801" }))))));
    };
    return EditStoreForm;
}(Component));
var mapPropsToFields = function (props) {
    var initialValue = props.initialValue;
    return {
        name: createFormField({
            value: initialValue.name
        }),
        address: createFormField({
            value: initialValue.address
        }),
        detailsAddress: createFormField({
            value: initialValue.houseNumber
        }),
        phone: createFormField({
            value: initialValue.phone
        }),
        type: createFormField({
            value: initialValue.type && String(initialValue.type)
        }),
        logoUrl: createFormField({
            value: initialValue.houseLogoImg
        }),
        numbering: createFormField({
            value: initialValue.numbering
        }),
        idCardFaceImg: createFormField({
            value: initialValue.idCardFaceImg
        }),
        idCardNationalEmblemImg: createFormField({
            value: initialValue.idCardNationalEmblemImg
        }),
        businessLicenceImg: createFormField({
            value: initialValue.businessLicenceImg
        }),
        storeFacadeImg: createFormField({
            value: initialValue.storeFacadeImg
        }),
        holdingIdCardImg: createFormField({
            value: initialValue.holdingIdCardImg
        }),
        holdingBusinessLicenceImg: createFormField({
            value: initialValue.holdingBusinessLicenceImg
        }),
        chargeName: createFormField({
            value: initialValue.chargeName
        }),
        chargeIdCardNumber: createFormField({
            value: initialValue.chargeIdCardNumber
        }),
        unifiedSocialCreditCode: createFormField({
            value: initialValue.unifiedSocialCreditCode
        }),
        promotionNumber: createFormField({
            value: initialValue.promotionNumber
        }),
        foodLicenseImg: createFormField({
            value: initialValue.foodLicenseImg
        }),
        medicalCertImg: createFormField({
            value: initialValue.medicalCertImg
        }),
        medicalOrgImg: createFormField({
            value: initialValue.medicalOrgImg
        }),
        medicalBusinessImg: createFormField({
            value: initialValue.medicalBusinessImg
        }),
        drugBusiLiceImg: createFormField({
            value: initialValue.drugBusiLiceImg
        }),
        tabaBusiLiceImg: createFormField({
            value: initialValue.tabaBusiLiceImg
        })
    };
};
var WrappedEditStoreForm = Form.create({ mapPropsToFields: mapPropsToFields })(EditStoreForm);
export default WrappedEditStoreForm;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
