var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Checkbox, Form, Input, Select } from 'antd';
import produce from 'immer';
import { find, get } from 'lodash';
import styled from 'styled-components';
import ajax from '@utils/ajax';
import { storeIdValidator } from '@utils/validator';
import { StoreCode } from '../../../../enums/codes';
import { FormType } from '../../../../enums/receipt';
import { PrinterTypes } from '../../../../printer/constants';
var FormItem = Form.Item;
var Option = Select.Option;
var formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 }
};
export var getPrinterListByStoreId = function (storeId) {
    return ajax.get("/printer/list?toPage=1&pageSize=20&storeId=" + storeId);
};
var BindPrintCaseList = styled.div.withConfig({ displayName: "BindPrintCaseList", componentId: "sc-1dlizw5" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 10px 0;\n  background: #fafafa;\n"], ["\n  padding: 10px 0;\n  background: #fafafa;\n"])));
var BindPrintCase = /** @class */ (function (_super) {
    __extends(BindPrintCase, _super);
    function BindPrintCase(props) {
        var _this = _super.call(this, props) || this;
        _this.handleStoreChange = function (storeId) {
            var setFieldsValue = _this.props.form.setFieldsValue;
            _this.state.typeList.forEach(function (type, index) {
                var _a;
                var key = "printTypes[" + type.code + "]";
                setFieldsValue((_a = {},
                    _a[key] = {
                        printerId: undefined,
                        printNumber: 1,
                        checked: false
                    },
                    _a));
            });
            getPrinterListByStoreId(storeId).then(function (_a) {
                var list = _a.data.list;
                var printerList = [];
                var printerListA4 = [];
                if (list && !!list.length) {
                    list.map(function (printer) {
                        if (printer.type === PrinterTypes.LocalUSBA4) {
                            printerListA4.push(printer);
                        }
                        else {
                            printerList.push(printer);
                        }
                    });
                }
                _this.setState(produce(function (draft) {
                    draft.printerList = printerList;
                    draft.printerListA4 = printerListA4;
                }));
            });
        };
        _this.state = {
            storeList: [],
            printerList: [],
            printerListA4: [],
            typeList: []
        };
        return _this;
    }
    BindPrintCase.prototype.renderStoreList = function () {
        return this.state.storeList.map(function (store) {
            return (React.createElement(Option, { key: store.id, value: store.id }, store.name));
        });
    };
    BindPrintCase.prototype.renderPrinterList = function (isA4) {
        var _a = this.state, printerList = _a.printerList, printerListA4 = _a.printerListA4;
        return (isA4 ? printerListA4 : printerList).map(function (printer) {
            return (React.createElement(Option, { key: printer.id, value: printer.id, disabled: printer.type === PrinterTypes.Bluetooth }, printer.name));
        });
    };
    BindPrintCase.prototype.componentDidMount = function () {
        var _this = this;
        var initialValue = this.props.initialValue;
        ajax.get('/printer/receipt/stencil/list').then(function (res) {
            var typeList = res.data.receipts.map(function (receiptData) {
                var group = receiptData.groups.find(function (group) {
                    return group.code === FormType.Store;
                });
                var receipt = group.receiptFields.find(function (receipt) {
                    return receipt.code === StoreCode.ReceiptName;
                });
                return {
                    code: receiptData.code,
                    value: receipt.data.text,
                    isA4: false
                };
            });
            ajax.get('/printer/a4/template/list').then(function (res) {
                var typeListA4 = res.data.map(function (receiptData) {
                    return {
                        code: receiptData.code,
                        value: get(receiptData, 'template.titleInfo.billName.value', ''),
                        isA4: true
                    };
                });
                _this.setState(produce(function (draft) {
                    draft.typeList = typeList.concat(typeListA4);
                }));
            });
        });
        // ajax.get('/dictionary/list?group=printType').then(({ data: typeList }) => {
        //   this.setState(
        //     produce(draft => {
        //       draft.typeList = typeList
        //     })
        //   )
        // })
        // const typeList = [
        //   {
        //     code: '1',
        //     value: '顾客联'
        //   },
        //   {
        //     code: '2',
        //     value: '商户联'
        //   },
        //   {
        //     code: '3',
        //     value: '生产联'
        //   }
        // ]
        // this.setState(
        //   produce(draft => {
        //     draft.typeList = typeList
        //   })
        // )
        if (!get(initialValue, 'id')) {
            ajax
                .get('/printer/scheme/stores?toPage=1&pageSize=40')
                .then(function (_a) {
                var list = _a.data.list;
                _this.setState(produce(function (draft) {
                    draft.storeList = list;
                }));
            });
        }
        else {
            getPrinterListByStoreId(initialValue.storeId).then(function (_a) {
                var list = _a.data.list;
                var printerList = [];
                var printerListA4 = [];
                if (list && !!list.length) {
                    list.map(function (printer) {
                        if (printer.type === PrinterTypes.LocalUSBA4) {
                            printerListA4.push(printer);
                        }
                        else {
                            printerList.push(printer);
                        }
                    });
                }
                _this.setState(produce(function (draft) {
                    draft.printerList = printerList;
                    draft.printerListA4 = printerListA4;
                }));
            });
        }
    };
    BindPrintCase.prototype.renderTypeList = function () {
        var _this = this;
        var state = this.state;
        var initialValue = this.props.initialValue;
        var _a = this.props.form, getFieldDecorator = _a.getFieldDecorator, getFieldValue = _a.getFieldValue;
        return state.typeList.map(function (type) {
            var key = "printTypes[" + type.code + "]";
            var printType = find(initialValue.printTypes, { code: type.code });
            var printerId = get(printType, 'printerId');
            var printTypeChecked = get(printType, 'checked', !!printType);
            var printNumber = get(printType, 'printNumber', 1);
            return (React.createElement(React.Fragment, { key: type.code },
                React.createElement(FormItem, { style: { display: 'none' } }, getFieldDecorator(key + ".code", {
                    initialValue: type.code
                })(React.createElement(Input, null))),
                React.createElement(FormItem, { style: { marginBottom: '6px', paddingLeft: '30px' } }, getFieldDecorator(key + ".checked", {
                    initialValue: printTypeChecked,
                    valuePropName: 'checked'
                })(React.createElement(Checkbox, null, type.value))),
                React.createElement(FormItem, { label: "\u9009\u62E9\u6253\u5370\u673A", style: { marginBottom: '6px' } }, getFieldDecorator(key + ".printerId", {
                    initialValue: printerId,
                    rules: [
                        {
                            required: getFieldValue(key + ".checked"),
                            message: '请选择打印机'
                        }
                    ]
                })(React.createElement(Select, { placeholder: "\u8BF7\u9009\u62E9\u6253\u5370\u673A" }, _this.renderPrinterList(type.isA4)))),
                React.createElement(FormItem, { label: "\u6253\u5370\u4EFD\u6570" }, getFieldDecorator(key + ".printNumber", {
                    initialValue: printNumber,
                    rules: [
                        {
                            required: getFieldValue(key + ".checked"),
                            message: '请输入打印份数'
                        }
                    ]
                })(React.createElement(Input, { type: "number", min: 0, placeholder: "\u8BF7\u8F93\u5165\u6253\u5370\u4EFD\u6570" })))));
        });
    };
    BindPrintCase.prototype.render = function () {
        var state = this.state;
        var initialValue = this.props.initialValue;
        var getFieldDecorator = this.props.form.getFieldDecorator;
        var storeId = get(initialValue, 'storeId');
        var storeName = get(initialValue, 'storeName');
        var schemeName = get(initialValue, 'schemeName');
        var isEditMode = !!storeId;
        return (React.createElement(Form, __assign({}, formItemLayout),
            React.createElement(FormItem, { label: "\u65B9\u6848\u540D\u79F0" }, getFieldDecorator('name', {
                initialValue: schemeName,
                rules: [
                    {
                        required: true,
                        message: '请输入方案名称'
                    }
                ]
            })(React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u65B9\u6848\u540D\u79F0" }))),
            React.createElement(FormItem, { label: isEditMode ? '当前门店' : '选择门店' }, getFieldDecorator('storeId', {
                initialValue: storeId,
                rules: [
                    {
                        required: !isEditMode,
                        validator: isEditMode
                            ? undefined
                            : storeIdValidator({ stores: state.storeList })
                    }
                ]
            })(React.createElement(Select, { placeholder: "\u8BF7\u9009\u62E9\u95E8\u5E97", onChange: this.handleStoreChange, disabled: isEditMode }, isEditMode ? (React.createElement(Option, { key: storeId, value: storeId }, storeName)) : (this.renderStoreList())))),
            React.createElement("p", null, "\u63A5\u5355\u65F6\u81EA\u52A8\u6253\u5370"),
            React.createElement(BindPrintCaseList, null, this.renderTypeList())));
    };
    return BindPrintCase;
}(React.Component));
export default Form.create()(BindPrintCase);
var templateObject_1;
