var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var SFlexLayout = styled.div.withConfig({ displayName: "SFlexLayout", componentId: "sc-7azf6i" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
export var SFlexLayoutObj = {
    'display': 'flex;',
    'justify-content': 'center;',
    'align-items': 'center;'
};
export var SEllipsisOne = styled.div.withConfig({ displayName: "SEllipsisOne", componentId: "sc-2cfqt9" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: block !important;\n  word-break: break-all;\n"], ["\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: block !important;\n  word-break: break-all;\n"])));
export var SEllipsisOneObj = {
    'white-space': 'nowrap;',
    'overflow': 'hidden;',
    'text-overflow': 'ellipsis;',
    'display': 'block !important;',
    'word-break': 'break-all;'
};
export var SEllipsisTwo = styled.div.withConfig({ displayName: "SEllipsisTwo", componentId: "sc-bjx6od" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-overflow: -o-ellipsis-lastline;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  line-clamp: 2;\n  -webkit-box-orient: vertical;\n  word-break: break-all;\n"], ["\n  text-overflow: -o-ellipsis-lastline;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  line-clamp: 2;\n  -webkit-box-orient: vertical;\n  word-break: break-all;\n"])));
export var SEllipsisTwoObj = {
    'overflow': 'hidden;',
    'text-overflow': 'ellipsis;',
    'display': '-webkit-box;',
    '-webkit-line-clamp': '2;',
    'line-clamp': '2;',
    '-webkit-box-orient': 'vertical;',
    'word-break': 'break-all;'
};
var templateObject_1, templateObject_2, templateObject_3;
