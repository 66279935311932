var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Button, Form, Input, message, Select } from 'antd';
import produce from 'immer';
import { debounce } from 'lodash';
import styled from 'styled-components';
import GeoRevise from '@components/GeoRevise';
import { getPlaceSuggestion } from '../../Stores/components/StoresManagement/EditStoreForm';
import { requestForOrder } from '@/utils/request';
import { getStoreRegion } from '../modal/EditOrderModal';
var createFormField = Form.createFormField;
var FormItem = Form.Item;
var TextArea = Input.TextArea;
var Option = Select.Option;
var formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 14 }
};
var AddressInputItem = styled.div.withConfig({ displayName: "AddressInputItem", componentId: "sc-19r2qjl" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: -12px;\n  left: 330px;\n"], ["\n  position: absolute;\n  top: -12px;\n  left: 330px;\n"])));
var GeoItem = styled.p.withConfig({ displayName: "GeoItem", componentId: "sc-1606l2j" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-sizing: border-box;\n  padding: 7px 12px;\n  border-radius: 3px;\n  background: #f4f4f5;\n  font-size: 14px;\n  line-height: 20px;\n"], ["\n  box-sizing: border-box;\n  padding: 7px 12px;\n  border-radius: 3px;\n  background: #f4f4f5;\n  font-size: 14px;\n  line-height: 20px;\n"])));
var EditOrder = /** @class */ (function (_super) {
    __extends(EditOrder, _super);
    function EditOrder() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            geo: {
                lng: 0,
                lat: 0 // 纬度
            },
            geoReviseVisible: false,
            placeSuggestions: [],
            storeRegion: "北京市"
        };
        _this.citySelect = React.createRef();
        _this.toggleGeoRevise = function () {
            _this.setState(produce(function (draft) {
                draft.geoReviseVisible = !_this.state.geoReviseVisible;
            }));
        };
        _this.handleGeoChange = function (_a) {
            var location = _a.location;
            _this.setState(produce(function (draft) {
                draft.geo.lng = location.lng;
                draft.geo.lat = location.lat;
            }));
        };
        _this.handleAddressChange = function (uid) {
            if (!uid) {
                _this.setState(produce(function (draft) {
                    draft.geo.lng = 0;
                    draft.geo.lat = 0;
                }));
                return false;
            }
            var place = _this.getPlaceByUid(uid.key);
            _this.setState(produce(function (draft) {
                draft.geo = place.location;
                draft.address = place.name;
            }));
        };
        _this.handleAddressSearch = function (query) {
            if (!query)
                return false;
            var region = _this.state.storeRegion;
            getPlaceSuggestion({ query: query, region: region }).then(function (_a) {
                var data = _a.data;
                var placeSuggestions = data.result;
                if (placeSuggestions) {
                    _this.setState(produce(function (draft) {
                        draft.placeSuggestions = placeSuggestions;
                    }));
                }
                else {
                    message.error(data.message);
                }
            });
        };
        _this.getPlaceByUid = function (uid) {
            return _this.state.placeSuggestions.filter(function (place) { return place.uid === uid; })[0];
        };
        return _this;
    }
    EditOrder.prototype.getStoreRegionforRequest = function () {
        var _this = this;
        var storeId = this.props.storeId;
        requestForOrder
            .get("/api/store/details", {
            id: storeId
        }, {
            showError: false
        })
            .then(function (_a) {
            var data = _a.data;
            _this.setState(produce(function (draft) {
                draft.storeRegion = getStoreRegion(data);
            }));
        });
    };
    EditOrder.prototype.componentDidMount = function () {
        var _a = this.props, initialValue = _a.initialValue, form = _a.form;
        form.setFieldsValue({
            geo: initialValue.geo
        });
        this.setState(produce(function (draft) {
            draft.geo = initialValue.geo;
        }));
        this.getStoreRegionforRequest();
    };
    EditOrder.prototype.render = function () {
        var _this = this;
        var _a = this.props.form, getFieldDecorator = _a.getFieldDecorator, setFieldsValue = _a.setFieldsValue;
        var state = this.state;
        var initialValue = this.props.initialValue;
        var geo = getFieldDecorator('geo');
        return (React.createElement(Form, __assign({}, formItemLayout),
            React.createElement(FormItem, { label: "\u6536\u8D27\u4EBA" }, getFieldDecorator('userName', {
                initialValue: initialValue.userName,
                rules: [
                    {
                        required: true,
                        message: '请填写收货人'
                    }
                ]
            })(React.createElement(Input, null))),
            React.createElement(FormItem, { label: "\u6536\u8D27\u4EBA\u7535\u8BDD" }, getFieldDecorator('phone', {
                initialValue: initialValue.phone.replace(/\s+/g, ''),
                rules: [
                    {
                        required: true,
                        message: '请填写收货人电话'
                    }
                ]
            })(React.createElement(Input, null))),
            React.createElement(FormItem, { label: "\u6536\u8D27\u5730\u5740" },
                getFieldDecorator('address', {
                    initialValue: { key: initialValue.address },
                    rules: [
                        {
                            required: true,
                            message: '请输入收货地址'
                        }
                    ]
                })(React.createElement(Select, { showSearch: true, defaultActiveFirstOption: false, showArrow: false, filterOption: false, onSearch: debounce(this.handleAddressSearch.bind(this), 1000), onChange: this.handleAddressChange, notFoundContent: null, placeholder: "\u8BF7\u8F93\u5165\u8BE6\u7EC6\u5730\u5740\uFF0C\u5982\uFF1A\u8F6F\u4EF6\u56ED\u4E8C\u671F63\u53F7\u56DB\u5C42", labelInValue: true }, state.placeSuggestions && state.placeSuggestions.map(function (place) {
                    return (React.createElement(Option, { key: place.uid, value: place.uid }, place.name));
                }))),
                React.createElement(React.Fragment, null,
                    React.createElement(AddressInputItem, null,
                        React.createElement(Button, { type: "primary", onClick: this.toggleGeoRevise }, "\u5730\u56FE\u6821\u6B63"),
                        React.createElement(GeoRevise, { visible: state.geoReviseVisible, geoPoint: state.geo, onCancel: this.toggleGeoRevise, onOk: function (_a) {
                                var lng = _a.lng, lat = _a.lat;
                                return _this.setState(produce(function (draft) {
                                    draft.geo.lng = lng;
                                    draft.geo.lat = lat;
                                }), function () {
                                    setFieldsValue({
                                        geo: {
                                            lng: lng,
                                            lat: lat
                                        }
                                    });
                                    _this.toggleGeoRevise();
                                });
                            } })),
                    React.createElement(GeoItem, null,
                        "\u7ECF\u5EA6:",
                        state.geo.lng,
                        " \u00A0\u00A0\u00A0\u00A0 \u7EAC\u5EA6:",
                        state.geo.lat))),
            React.createElement(FormItem, { label: "\u5907\u6CE8" }, getFieldDecorator('remarks', {
                initialValue: initialValue.remarks
            })(React.createElement(TextArea, null)))));
    };
    return EditOrder;
}(React.Component));
export var EditOrderForm = Form.create({})(EditOrder);
var templateObject_1, templateObject_2;
