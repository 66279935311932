var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Table } from 'antd';
import styled from 'styled-components';
import { Colors } from '@assets/styles/variables';
export var OrderPlatformTable = styled(Table).withConfig({ displayName: "OrderPlatformTable", componentId: "sc-jbp09m" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 20px;\n"], ["\n  margin-top: 20px;\n"])));
export var BindTipsWrapper = styled.div.withConfig({ displayName: "BindTipsWrapper", componentId: "sc-1q5utm1" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 33px;\n  padding: 0 40px;\n"], ["\n  margin-top: 33px;\n  padding: 0 40px;\n"])));
export var BindTipsTitle = styled.h5.withConfig({ displayName: "BindTipsTitle", componentId: "sc-u5rfaa" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  margin-bottom: 18px;\n  padding-left: 9px;\n  color: #333;\n  line-height: 22px;\n  font-size: 16px;\n  &::before {\n    content: ' ';\n    position: absolute;\n    top: 50%;\n    bottom: 0;\n    left: 0;\n    margin-top: -7px;\n    width: 4px;\n    height: 14px;\n    background: ", ";\n  }\n"], ["\n  position: relative;\n  margin-bottom: 18px;\n  padding-left: 9px;\n  color: #333;\n  line-height: 22px;\n  font-size: 16px;\n  &::before {\n    content: ' ';\n    position: absolute;\n    top: 50%;\n    bottom: 0;\n    left: 0;\n    margin-top: -7px;\n    width: 4px;\n    height: 14px;\n    background: ", ";\n  }\n"])), Colors.Green95);
export var BindTipsText = styled.p.withConfig({ displayName: "BindTipsText", componentId: "sc-1t8d7r" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0;\n  color: #666;\n  font-size: 14px;\n  line-height: 23px;\n"], ["\n  margin: 0;\n  color: #666;\n  font-size: 14px;\n  line-height: 23px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
