var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Row, Col } from 'antd';
import { transformConsigneePhone } from '@/models/orders';
import OrderActions from '../OrderActions';
import { ORDER_STATUS_TEXT_MAP } from '../constants';
import OrderTags from '../OrderTags';
import { ORDER_PLATFORM_MAP, ORDER_PLATFORM_TEXT_MAP } from '@utils/constants';
import styled from 'styled-components';
import { productTypes } from '@/utils/data';
export var AddressIcon = styled.span.withConfig({ displayName: "AddressIcon", componentId: "sc-1jnoc3s" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  width: 20px;\n  height: 20px;\n  background-color: #01cccc;\n  color: #fff;\n  margin-right: 10px;\n  border-radius: 50%;\n  font-size: 12px;\n"], ["\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  width: 20px;\n  height: 20px;\n  background-color: #01cccc;\n  color: #fff;\n  margin-right: 10px;\n  border-radius: 50%;\n  font-size: 12px;\n"])));
var OrderItem = function (_a) {
    var _b;
    var order = _a.order, current = _a.current, onChange = _a.onChange;
    var handleClick = function (order) {
        onChange(order);
    };
    var renderDeliveryTime = function (order) {
        var _a;
        if (order.platform === ORDER_PLATFORM_MAP.EBAI &&
            order.reservationStartTime) {
            return (React.createElement("span", null,
                "\u9001\u8FBE\u65F6\u95F4\uFF1A",
                order.deliverTime,
                "\u00A0\u5230\u00A0",
                order.reservationStartTime));
        }
        else if (((_a = order.selfFetch) === null || _a === void 0 ? void 0 : _a.code) === 0) {
            return React.createElement("span", null,
                "\u5230\u5E97\u81EA\u53D6\uFF1A",
                order.deliverTime);
        }
        else {
            return React.createElement("span", null,
                "\u9001\u8FBE\u65F6\u95F4\uFF1A",
                order.deliverTime);
        }
    };
    var good = order.goods.length ? order.goods[0] : null;
    var _c = __read(good.name.split(','), 2), productType = _c[0], weight = _c[1];
    var productTypeText = (_b = productTypes.find(function (t) { return t.value === productType; })) === null || _b === void 0 ? void 0 : _b.label;
    return (React.createElement("div", { className: "order-item " + (order.id === current ? 'active' : ''), key: order.id, onClick: function () { return handleClick(order); } },
        React.createElement(Row, { className: "header" },
            React.createElement(Col, { span: 8, className: "no" },
                ORDER_PLATFORM_TEXT_MAP[order.platform],
                "#",
                order.platformDaySn),
            React.createElement(Col, { span: 8 },
                "\u8BA2\u5355\u72B6\u6001\uFF1A",
                React.createElement("span", { className: "order-status" }, ORDER_STATUS_TEXT_MAP[order.status])),
            React.createElement(Col, { span: 8, className: "arrived-date" }, renderDeliveryTime(order))),
        React.createElement("div", { className: "content" },
            React.createElement(Row, { className: "row" },
                React.createElement(Col, { span: 8 },
                    React.createElement(AddressIcon, null, "\u53D1"),
                    React.createElement("span", null, order.storeName)),
                React.createElement(Col, { span: 8 },
                    order.storeUser && (React.createElement("span", { style: { marginRight: '20px' } }, order.storeUser)),
                    React.createElement("span", null, order.storePhone))),
            React.createElement(Row, { className: "row" },
                React.createElement(Col, { span: 8 },
                    React.createElement(AddressIcon, null, "\u6536"),
                    React.createElement("span", null, order.consigneeAddress),
                    React.createElement("span", { className: "distance" }, order.distance ? "[" + order.distance + "km]" : '')),
                React.createElement(Col, { span: 8 },
                    React.createElement("span", { style: { marginRight: '20px' } }, order.consignee),
                    React.createElement("span", null, transformConsigneePhone({
                        platform: order.platform,
                        consigneePhone: order.consigneePhone
                    }))),
                React.createElement(Col, { span: 8, className: "right" },
                    "\u4E0B\u5355\u65F6\u95F4\uFF1A",
                    order.orderTime)),
            React.createElement(Row, null,
                React.createElement(Col, { span: 8 },
                    React.createElement("span", { className: "strong" },
                        "\u5546\u54C1\u4FE1\u606F\uFF1A", productTypeText + "\uFF0C" + weight + "\u516C\u65A4\u5185"),
                    ' '),
                React.createElement(Col, { span: 6 },
                    "\u5907\u6CE8\uFF1A",
                    order.description),
                React.createElement(Col, { span: 10, className: "right" },
                    "\u6613\u9001\u6D41\u6C34\u53F7\uFF1A #",
                    order.daySn,
                    "\uFF0C \u5E73\u53F0\u8BA2\u5355\u53F7\uFF1A",
                    order.platformId,
                    "\uFF0C \u6253\u5370\u5F20\u6570\uFF1A",
                    order.printNum))),
        React.createElement("div", { className: "tags" }, OrderTags(order).map(function (tag, index) {
            return React.createElement("span", { key: index }, tag);
        })),
        React.createElement("div", { className: "actions-wrapper", onClick: function (e) { return e.stopPropagation(); } },
            React.createElement(OrderActions, { item: order }))));
};
export default OrderItem;
var templateObject_1;
