var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Table } from 'antd';
import styled from 'styled-components';
import { Colors } from '@assets/styles/variables';
export var EditStrategyTips = styled.div.withConfig({ displayName: "EditStrategyTips", componentId: "sc-1q08t5h" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 33px;\n  padding: 0 40px;\n"], ["\n  margin-top: 33px;\n  padding: 0 40px;\n"])));
export var EditStrategyTipsTitle = styled.h5.withConfig({ displayName: "EditStrategyTipsTitle", componentId: "sc-2b6h3d" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  margin-bottom: 18px;\n  padding-left: 9px;\n  color: #333;\n  line-height: 22px;\n  font-size: 16px;\n  &::before {\n    content: ' ';\n    position: absolute;\n    top: 50%;\n    bottom: 0;\n    left: 0;\n    margin-top: -7px;\n    width: 4px;\n    height: 14px;\n    background: ", ";\n  }\n"], ["\n  position: relative;\n  margin-bottom: 18px;\n  padding-left: 9px;\n  color: #333;\n  line-height: 22px;\n  font-size: 16px;\n  &::before {\n    content: ' ';\n    position: absolute;\n    top: 50%;\n    bottom: 0;\n    left: 0;\n    margin-top: -7px;\n    width: 4px;\n    height: 14px;\n    background: ", ";\n  }\n"])), Colors.Green95);
export var EditStrategyTipsText = styled.p.withConfig({ displayName: "EditStrategyTipsText", componentId: "sc-1clv72r" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0;\n  color: #666;\n  font-size: 14px;\n  line-height: 23px;\n"], ["\n  margin: 0;\n  color: #666;\n  font-size: 14px;\n  line-height: 23px;\n"])));
export var StoresTable = styled(Table).withConfig({ displayName: "StoresTable", componentId: "sc-11lkql3" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 20px;\n"], ["\n  margin-top: 20px;\n"])));
export var EditModeWrapper = styled.div.withConfig({ displayName: "EditModeWrapper", componentId: "sc-iobrin" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
export var EditModeText = styled.span.withConfig({ displayName: "EditModeText", componentId: "sc-ptyqa4" })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
