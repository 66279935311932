var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'antd';
import styled from 'styled-components';
import LoginBackgroundImgUrl from '@assets/images/bg-login.png';
export var LoginWrapper = styled.div.withConfig({ displayName: "LoginWrapper", componentId: "sc-kbgfx9" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100vw;\n  height: 100vh;\n  background: url(", ") no-repeat center/cover;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100vw;\n  height: 100vh;\n  background: url(", ") no-repeat center/cover;\n"])), LoginBackgroundImgUrl);
export var Logo = styled.img.withConfig({ displayName: "Logo", componentId: "sc-16xq7qc" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 105px;\n  width: 100px;\n  height: 100px;\n"], ["\n  margin-top: 105px;\n  width: 100px;\n  height: 100px;\n"])));
export var Title = styled.h2.withConfig({ displayName: "Title", componentId: "sc-1rlvgm" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 10px;\n  margin-bottom: 46px;\n  font-size: 40px;\n  color: #fff;\n"], ["\n  margin-top: 10px;\n  margin-bottom: 46px;\n  font-size: 40px;\n  color: #fff;\n"])));
export var Panel = styled.section.withConfig({ displayName: "Panel", componentId: "sc-1n1ecbq" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  box-sizing: border-box;\n  padding: 40px 40px 70px;\n  width: 480px;\n  height: 445px;\n  background: #fff;\n  border-radius: 5px;\n  .ant-tabs-bar {\n    margin-bottom: 40px;\n  }\n  .ant-tabs-nav {\n    .ant-tabs-tab {\n      font-size: 16px;\n    }\n    .ant-tabs-tab-active {\n      font-size: 20px;\n    }\n  }\n  .ant-input {\n    height: 48px;\n  }\n  .ant-form-item {\n    margin-bottom: 20px;\n  }\n"], ["\n  box-sizing: border-box;\n  padding: 40px 40px 70px;\n  width: 480px;\n  height: 445px;\n  background: #fff;\n  border-radius: 5px;\n  .ant-tabs-bar {\n    margin-bottom: 40px;\n  }\n  .ant-tabs-nav {\n    .ant-tabs-tab {\n      font-size: 16px;\n    }\n    .ant-tabs-tab-active {\n      font-size: 20px;\n    }\n  }\n  .ant-input {\n    height: 48px;\n  }\n  .ant-form-item {\n    margin-bottom: 20px;\n  }\n"])));
export var LoginButton = styled(Button).withConfig({ displayName: "LoginButton", componentId: "sc-1gz4whw" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 20px;\n"], ["\n  margin-top: 20px;\n"])));
export var OtherControls = styled.div.withConfig({ displayName: "OtherControls", componentId: "sc-ssfzdi" })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 14px;\n"], ["\n  display: flex;\n  margin-top: 14px;\n"])));
export var UserAgreementWrapper = styled.div.withConfig({ displayName: "UserAgreementWrapper", componentId: "sc-1yg0vd7" })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
export var Version = styled.p.withConfig({ displayName: "Version", componentId: "sc-46eyx4" })(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  margin-bottom: 10px;\n  color: #fff;\n  text-align: center;\n"], ["\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  margin-bottom: 10px;\n  color: #fff;\n  text-align: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
