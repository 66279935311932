var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Tabs } from 'antd';
import styled from 'styled-components';
import BindDeliveryPlatform from './components/BindDeliveryPlatform';
import BindOrderPlatform from './components/BindOrderPlatform';
import PrinterManagement from './components/PrinterManagement';
import StoresManagement from './components/StoresManagement';
import { getAuth } from '../AccountManagement/component';
import { useSelector } from 'react-redux';
var StoresTabKeys;
(function (StoresTabKeys) {
    StoresTabKeys["STORES_MANAGEMENT"] = "STORES_MANAGEMENT";
    StoresTabKeys["BIND_ORDER_PLATFORM"] = "BIND_ORDER_PLATFORM";
    StoresTabKeys["BIND_DELIVERY_PLATFORM"] = "BIND_DELIVERY_PLATFORM";
    StoresTabKeys["BIND_PRINT"] = "BIND_PRINT";
})(StoresTabKeys || (StoresTabKeys = {}));
var TabPane = Tabs.TabPane;
var StoresWrapper = styled.div.withConfig({ displayName: "StoresWrapper", componentId: "sc-zzg9ne" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0 16px;\n  background: #fff;\n"], ["\n  padding: 0 16px;\n  background: #fff;\n"])));
var Stores = function () {
    var match = useRouteMatch();
    var history = useHistory();
    var pathname = useLocation().pathname;
    var storesTabs = getMenu();
    var getFullPath = function (compPath) {
        return "" + match.path + compPath;
    };
    var handleTabChange = function (activeKey) {
        var activeTab = storesTabs.filter(function (store) { return store.key === activeKey; })[0];
        var path = getFullPath(activeTab.path);
        history.push(path);
    };
    var activeTab = storesTabs.filter(function (store) { return pathname.includes(store.path); })[0];
    if (!activeTab) {
        history.push(getFullPath(storesTabs[0].path));
        return null;
    }
    return (React.createElement(StoresWrapper, null,
        React.createElement(Tabs, { animated: false, onChange: handleTabChange, activeKey: activeTab.key }, storesTabs.map(function (store) {
            return (React.createElement(TabPane, { tab: store.name, key: store.key },
                React.createElement(Route, { path: getFullPath(store.path), component: store.component })));
        }))));
    function getMenu() {
        var DEFAULTMENU = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
        var conststoresTabs = [
            {
                key: StoresTabKeys.STORES_MANAGEMENT,
                name: '门店管理',
                path: '/management',
                component: StoresManagement,
                secret: !getAuth("门店管理", DEFAULTMENU)
            },
            {
                key: StoresTabKeys.BIND_ORDER_PLATFORM,
                name: '接单平台管理',
                path: '/order_platform',
                component: BindOrderPlatform,
                secret: !getAuth("绑定接单平台", DEFAULTMENU)
            },
            {
                key: StoresTabKeys.BIND_DELIVERY_PLATFORM,
                name: '配送平台管理',
                path: '/delivery_platform',
                component: BindDeliveryPlatform,
                secret: !getAuth("绑定运力平台", DEFAULTMENU)
            },
            {
                key: StoresTabKeys.BIND_PRINT,
                name: '打印方案管理',
                path: '/print',
                component: PrinterManagement,
                secret: !getAuth("打印设置", DEFAULTMENU)
            }
        ];
        var storesTabs = conststoresTabs.filter(function (store) { return !store.secret; });
        // console.log("storesTabs",DEFAULTMENU,storesTabs);
        return storesTabs;
    }
};
export default Stores;
var templateObject_1;
