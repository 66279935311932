var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * @Author: hijack
 * @Date: 2021-07-21 14:45:07
 * @LastEditTime: 2021-07-21 16:06:19
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/container/AgreementPrivacy/styled.ts
 */
import styled from 'styled-components';
export var AgreementWrapper = styled.div.withConfig({ displayName: "AgreementWrapper", componentId: "sc-1m5kmyv" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px 0;\n"], ["\n  padding: 20px 0;\n"])));
export var AgreementContent = styled.div.withConfig({ displayName: "AgreementContent", componentId: "sc-nsveav" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  h1 {\n    text-align: center;\n  }\n  h2 {\n    padding-left: 35px;\n  }\n  p {\n    padding: 0 35px;\n  }\n  ul {\n    padding-left: 60px;\n  }\n\n  table,table tr th, table tr td {\n    border: 1px solid #EEEEEE; \n  }\n  table {\n    min-height: 25px;\n    line-height: 25px;\n    text-align: center;\n    border-collapse: collapse;\n    padding: 2px;\n    margin: 0 35px;\n    margin-bottom: 1em;\n  } \n  thead {\n    background: #EEEEEE;\n  }\n  td {\n    padding: 15px;\n  }\n"], ["\n  h1 {\n    text-align: center;\n  }\n  h2 {\n    padding-left: 35px;\n  }\n  p {\n    padding: 0 35px;\n  }\n  ul {\n    padding-left: 60px;\n  }\n\n  table,table tr th, table tr td {\n    border: 1px solid #EEEEEE; \n  }\n  table {\n    min-height: 25px;\n    line-height: 25px;\n    text-align: center;\n    border-collapse: collapse;\n    padding: 2px;\n    margin: 0 35px;\n    margin-bottom: 1em;\n  } \n  thead {\n    background: #EEEEEE;\n  }\n  td {\n    padding: 15px;\n  }\n"])));
export var BackButton = styled.div.withConfig({ displayName: "BackButton", componentId: "sc-1uuhvab" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 20px;\n  text-align: center;\n  font-size: 16px;\n"], ["\n  margin-top: 20px;\n  text-align: center;\n  font-size: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
