var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import styled from 'styled-components';
import { message, Modal } from 'antd';
import lodash, { cloneDeep } from 'lodash';
import { requestForOrder } from '@utils/request';
import { getgeneralNumberCheck } from '@/apis/generalNumber';
import { CreateOrderAction } from '../action';
import { EDeliveryType, ERouteType, UseCoupon } from '../constants';
import OrderMapList from './OrderMapList';
import OrderMapCalculateBottom from './OrderMapCalculateBottom';
import SendDeliveryCalculate from '../form/SendDeliveryCalculate';
import { declaredAmountforRequest } from './InsuranceComponent';
var SOrderMapCalculateWrapper = styled.div.withConfig({ displayName: "SOrderMapCalculateWrapper", componentId: "sc-1j74e1b" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  padding: 0;\n  overflow-y: hidden;\n  background: #f6f8fb;\n  border-radius: 10px;\n  box-shadow: 0px 2px 15px 0px rgb(0 12 40 / 10%);\n  width: 350px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n\n  .content {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    overflow-y: auto;\n    padding: 20px 0;\n  }\n\n  .creatOrderBox {\n    display: flex;\n    justify-content: center;\n    margin-top: 10px;\n  }\n"], ["\n  width: 100%;\n  height: 100%;\n  padding: 0;\n  overflow-y: hidden;\n  background: #f6f8fb;\n  border-radius: 10px;\n  box-shadow: 0px 2px 15px 0px rgb(0 12 40 / 10%);\n  width: 350px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n\n  .content {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    overflow-y: auto;\n    padding: 20px 0;\n  }\n\n  .creatOrderBox {\n    display: flex;\n    justify-content: center;\n    margin-top: 10px;\n  }\n"])));
var calculate = function (_a) {
    var _b = _a.orderIds, orderIds = _b === void 0 ? [] : _b, _c = _a.storeId, storeId = _c === void 0 ? '' : _c, useCoupon = _a.useCoupon, _d = _a.expressPartnerCouponInfos, expressPartnerCouponInfos = _d === void 0 ? [] : _d, _e = _a.appointType, appointType = _e === void 0 ? 0 : _e, appointPickUpTime = _a.appointPickUpTime, additionAmount = _a.additionAmount, _f = _a.payAccountType, payAccountType = _f === void 0 ? 2 : _f, _g = _a.remarks, remarks = _g === void 0 ? '' : _g, dispatchType = _a.dispatchType, routeType = _a.routeType, _h = _a.declaredAmount, declaredAmount = _h === void 0 ? 0 : _h;
    var data = {
        orderIds: orderIds,
        storeId: storeId,
        useCoupon: useCoupon,
        expressPartnerCouponInfos: expressPartnerCouponInfos,
        appointType: appointType,
        appointPickUpTime: appointPickUpTime,
        additionAmount: additionAmount,
        payAccountType: payAccountType,
        remarks: remarks,
        dispatchType: dispatchType,
        routeType: routeType,
        declaredAmount: declaredAmount
    };
    return requestForOrder.post('/api/platform/order/getPrice', data);
};
var OrderMapCalculate = function (props, ref) {
    var handleReceiverClick = props.handleReceiverClick, oneByMoreData = props.oneByMoreData, handleRefreshOrderMap = props.handleRefreshOrderMap, mapSearchRef = props.mapSearchRef, routeType = props.routeType, handleSortOneMoreData = props.handleSortOneMoreData, oneByMoreOrderIds = props.oneByMoreOrderIds;
    var _a = __read(useState((oneByMoreData[0] && oneByMoreData[0].payAccountTypes[0]) || 2), 2), currentTab = _a[0], setcurrentTab = _a[1];
    var _b = __read(useState(0), 2), balance = _b[0], setbalance = _b[1];
    var _c = __read(useState(0), 2), maxPayAmount = _c[0], setmaxPayAmount = _c[1];
    var _d = __read(useState([]), 2), platformChecked = _d[0], setplatformChecked = _d[1];
    var _e = __read(useState([]), 2), valuations = _e[0], setvaluations = _e[1];
    var _f = __read(useState(false), 2), confirmLoading = _f[0], setconfirmLoading = _f[1];
    var _g = __read(useState(null), 2), channelDispatchType = _g[0], setchannelDispatchType = _g[1];
    var _h = __read(useState(false), 2), showSwitchTab = _h[0], setShowSwitchTab = _h[1];
    var _j = __read(useState(0), 2), totalDistance = _j[0], setTotalDistance = _j[1];
    var formRef = useRef();
    useEffect(function () {
        if (oneByMoreData && oneByMoreData.length > 0) {
            handleFieldChange(null, function (data) {
                var payAmount = 0;
                var platformIds = [];
                var tranPriceList = data.tranPriceList;
                tranPriceList &&
                    tranPriceList.map(function (item) {
                        if (item.checked || tranPriceList.length === 1) {
                            platformIds.push(item.platform);
                        }
                        if (item.amount - item.reducedPrice > payAmount) {
                            payAmount = item.amount - item.reducedPrice;
                        }
                    });
                handleUpdatePlatformChecked(platformIds);
                if (currentTab === EDeliveryType.Platform || oneByMoreData.length > 1) {
                    setbalance(data.balance);
                    setmaxPayAmount(payAmount);
                }
                else {
                    setchannelDispatchType(data.dispatchType);
                }
            });
            if (oneByMoreData.length === 1) {
                getgeneralNumberCheck().then(function (res) {
                    //TODO：渠道模式一取多投：需要接口返回 多投方式有几个。只有单个 涉及到显示问题。暂定不让点击
                    setShowSwitchTab(res && oneByMoreData[0].payAccountTypes.length === 2 ? true : false);
                });
            }
            else {
                //TODO：渠道模式一取多投：需要接口返回 多投方式有几个。只有单个 涉及到显示问题。暂定不让点击
                if (props.onetoManyConfig !== '12') {
                    setcurrentTab(Number(props.onetoManyConfig.replace('0', '')));
                }
                setShowSwitchTab(props.onetoManyConfig === '12');
            }
            // handleTotalDistance()
        }
    }, [oneByMoreData]);
    var handleTotalDistance = function () {
        var totalDistance = 0;
        oneByMoreData &&
            oneByMoreData.map(function (item, index) {
                totalDistance += (item.distance && Number(item.distance)) || 0;
            });
        setTotalDistance(totalDistance.toFixed(2));
    };
    var handleUpdatePlatformChecked = function (platformChecked, callback) { return __awaiter(void 0, void 0, void 0, function () {
        var setFieldsValue;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, setplatformChecked(platformChecked)];
                case 1:
                    _a.sent();
                    try {
                        setFieldsValue = formRef.current.form.setFieldsValue;
                        setFieldsValue({ platform: platformChecked });
                    }
                    catch (error) {
                        console.log(error);
                    }
                    return [4 /*yield*/, callback];
                case 2:
                    (_a.sent()) && callback();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleCalculate = function (_a) {
        var payload = _a.payload, callback = _a.callback;
        if (payload && payload.payAccountType) {
            setconfirmLoading(true);
            //  TODO： 渠道模式一取多投
            calculate(payload).then(function (_a) {
                var data = _a.data;
                return __awaiter(void 0, void 0, void 0, function () {
                    var tranPriceList, distance, sortOrderIds, flag_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                tranPriceList = data.tranPriceList, distance = data.distance, sortOrderIds = data.sortOrderIds;
                                return [4 /*yield*/, setvaluations(tranPriceList || [])];
                            case 1:
                                _b.sent();
                                return [4 /*yield*/, setTotalDistance(distance || '')];
                            case 2:
                                _b.sent();
                                if (data && sortOrderIds) {
                                    flag_1 = false;
                                    sortOrderIds.map(function (id, index) {
                                        if (id !== oneByMoreData[index].id) {
                                            flag_1 = true;
                                        }
                                    });
                                    flag_1 && handleSortOneMoreData(sortOrderIds);
                                }
                                if (callback) {
                                    callback(data || {});
                                }
                                setconfirmLoading(false);
                                return [2 /*return*/];
                        }
                    });
                });
            }, function (err) {
                setvaluations([]);
                setconfirmLoading(false);
            });
        }
    };
    var handleFieldChange = function (values, callback) { return __awaiter(void 0, void 0, void 0, function () {
        var getFieldsValue, _a, reservationTime, dispatchType, tip, appointType, remarks, expressPartnerCoupon, orderIds, storeId, payload;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    console.log('handleFieldChange', values);
                    getFieldsValue = formRef.current.form.getFieldsValue;
                    _a = getFieldsValue(), reservationTime = _a.reservationTime, dispatchType = _a.dispatchType, tip = _a.tip, appointType = _a.appointType, remarks = _a.remarks;
                    if (!(values && values.tab)) return [3 /*break*/, 2];
                    return [4 /*yield*/, setcurrentTab(values.tab)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    expressPartnerCoupon = (values === null || values === void 0 ? void 0 : values.expressPartnerCouponInfos) || [];
                    if (expressPartnerCoupon && expressPartnerCoupon[0]) {
                        if ([0, -1].includes(expressPartnerCoupon[0].couponGroupId)) {
                            expressPartnerCoupon = [];
                        }
                    }
                    orderIds = [];
                    oneByMoreData.map(function (item) {
                        orderIds.push(item.id);
                    });
                    storeId = oneByMoreData[0].storeId;
                    payload = {
                        useCoupon: (values === null || values === void 0 ? void 0 : values.useCoupon) === false ? UseCoupon.Unuse : UseCoupon.Use,
                        expressPartnerCouponInfos: expressPartnerCoupon,
                        additionAmount: Math.abs(tip) * 100,
                        appointType: appointType,
                        appointPickUpTime: reservationTime
                            ? Number(reservationTime.format('x'))
                            : '',
                        remarks: remarks,
                        orderIds: values && values.routeType === ERouteType.Assign
                            ? oneByMoreOrderIds
                            : orderIds,
                        storeId: storeId,
                        //TODO: 传参修改 渠道模式一取多投
                        payAccountType: oneByMoreData.length > 1 && props.onetoManyConfig !== '12'
                            ? Number(props.onetoManyConfig.replace('0', ''))
                            : values && values.tab
                                ? values.tab
                                : currentTab,
                        routeType: (values && values.routeType) || routeType,
                        declaredAmount: declaredAmountforRequest((values === null || values === void 0 ? void 0 : values.declaredAmount) || 0)
                    };
                    if (currentTab === EDeliveryType.Channel) {
                        payload.dispatchType = dispatchType;
                    }
                    handleCalculate({ payload: payload, callback: callback });
                    return [2 /*return*/];
            }
        });
    }); };
    function fn(params) {
        var _this = this;
        handleFieldChange(params, function (data) { return __awaiter(_this, void 0, void 0, function () {
            var tab, tranPriceList, platformIds_1, payAmount_1, platformIds_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tab = params.tab;
                        tranPriceList = data.tranPriceList;
                        if (!(tab === EDeliveryType.Platform)) return [3 /*break*/, 3];
                        platformIds_1 = [];
                        payAmount_1 = 0;
                        tranPriceList &&
                            tranPriceList.map(function (item) {
                                if (item.checked) {
                                    platformIds_1.push(item.platform);
                                }
                                if (item.amount - item.reducedPrice > payAmount_1) {
                                    payAmount_1 = item.amount - item.reducedPrice;
                                }
                            });
                        return [4 /*yield*/, setbalance(data.balance)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, setmaxPayAmount(payAmount_1)];
                    case 2:
                        _a.sent();
                        handleUpdatePlatformChecked(platformIds_1);
                        return [3 /*break*/, 4];
                    case 3:
                        platformIds_2 = [];
                        tranPriceList &&
                            tranPriceList.map(function (item) {
                                if (tranPriceList.length === 1 && !tranPriceList[0]['errMsg']) {
                                    platformIds_2.push(item.platform);
                                }
                                if (item.checked) {
                                    platformIds_2.push(item.platform);
                                }
                            });
                        setchannelDispatchType(data.dispatchType);
                        handleUpdatePlatformChecked(platformIds_2);
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    }
    var handleSwitchTab = function (tab) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            //TODO: 传参修改 渠道模式一取多投
            fn({ tab: tab });
            return [2 /*return*/];
        });
    }); }; };
    var handleTipChange = function (tip) {
        var valuationsTemp = cloneDeep(valuations);
        valuationsTemp.map(function (v) {
            v.amount = (Number(v.amount || 0) + tip).toFixed(2);
            return v;
        });
        setvaluations(valuationsTemp);
    };
    var submitSendDelivery = function () {
        var validateFields = formRef.current.form.validateFields;
        setconfirmLoading(true);
        validateFields(function (errors, values) {
            var _a;
            if (errors) {
                if (errors.platform) {
                    message.error('请选择配送平台');
                }
                setconfirmLoading(false);
                return false;
            }
            var reservationTime = values.reservationTime
                ? values.reservationTime.format('x')
                : '';
            var useCoupon = ((_a = values.platformCoupon) === null || _a === void 0 ? void 0 : _a.globalCouponId) === 0
                ? UseCoupon.Unuse
                : UseCoupon.Use;
            var couponInfos = [];
            valuations.map(function (item) {
                if (item.couponGroupId && values.platform.includes(item.platform)) {
                    couponInfos.push({
                        couponGroupId: item.couponGroupId,
                        partnerPlatform: item.platform
                    });
                }
            });
            if (balance < maxPayAmount &&
                (currentTab === EDeliveryType.Platform || oneByMoreData.length > 1)) {
                message.error('余额不足，请充值');
                setconfirmLoading(false);
                return false;
            }
            var orderIds = [];
            oneByMoreData.map(function (item) { return orderIds.push(item.id); });
            var payload = {
                orderIds: routeType === ERouteType.Assign ? oneByMoreOrderIds : orderIds,
                storeId: oneByMoreData[0].storeId,
                payAccountType: oneByMoreData.length > 1 && props.onetoManyConfig !== '12'
                    ? Number(props.onetoManyConfig.replace('0', ''))
                    : values && values.tab
                        ? values.tab
                        : currentTab,
                additionAmount: Math.abs(values.tip) * 100,
                appointType: values.appointType,
                appointPickUpTime: reservationTime,
                remarks: values.remarks,
                couponInfos: couponInfos,
                couponType: useCoupon,
                platform: Array.from(new Set(values.platform)),
                source: 1,
                routeType: routeType,
                declaredAmount: declaredAmountforRequest(lodash.get(formRef, 'current.declaredAmount', 0))
            };
            if (currentTab === EDeliveryType.Channel) {
                payload.dispatchType = values.dispatchType;
            }
            requestForOrder
                .post('/api/platform/order/pay', payload, { showError: false })
                .then(function (_a) {
                var data = _a.data, code = _a.code, err = _a.err;
                if (code === 0) {
                    setconfirmLoading(false);
                    message.success('下单成功');
                    handleRefreshOrderMap();
                }
                else {
                    setconfirmLoading(false);
                    message.error(err);
                }
            })
                .catch(function (data) {
                Modal.error({
                    title: '异常',
                    content: data.msg
                });
                setconfirmLoading(false);
            });
        });
    };
    var handleCreateOrder = function (data) {
        if (data) {
            handleReceiverClick(data);
        }
    };
    useImperativeHandle(ref, function () {
        var _a = ref.current, current = _a === void 0 ? {} : _a;
        return __assign(__assign({}, current), { handleFieldChange: handleFieldChange });
    });
    var isDisabled = useCallback(function () {
        if (currentTab === EDeliveryType.Channel && valuations.length === 1) {
            return Boolean(valuations[0]['errMsg']);
        }
        return false;
    }, [currentTab, JSON.stringify(valuations[0])]);
    return (React.createElement(SOrderMapCalculateWrapper, null,
        React.createElement("div", { className: "content" },
            oneByMoreData &&
                oneByMoreData.map(function (item) { return (React.createElement(OrderMapList, { oneByMoreDataItem: item, handleReceiverClick: handleReceiverClick, key: item.id })); }),
            oneByMoreData.length < 3 && (React.createElement("div", { className: "creatOrderBox" },
                React.createElement(CreateOrderAction, { callback: function (data) { return handleCreateOrder(data); }, oneByMoreData: oneByMoreData, searchParams: mapSearchRef === null || mapSearchRef === void 0 ? void 0 : mapSearchRef.current }))),
            React.createElement(SendDeliveryCalculate, { wrappedComponentRef: formRef, handleSwitchTab: function (tab) { return handleSwitchTab(tab); }, fn: fn, showSwitchTab: showSwitchTab, valuations: valuations, isDisabled: isDisabled, triggerChange: handleFieldChange, handleTipChange: handleTipChange, platformInit: {
                    platformChecked: platformChecked,
                    handleUpdatePlatformChecked: handleUpdatePlatformChecked,
                    balance: balance,
                    handleUpdateMaxPayAmount: setmaxPayAmount,
                    maxPayAmount: maxPayAmount,
                    channelDispatchType: channelDispatchType
                }, loading: confirmLoading, currentTab: currentTab, oneByMoreData: oneByMoreData, totalDistance: totalDistance })),
        React.createElement(OrderMapCalculateBottom, { oneByMoreData: oneByMoreData, handleClick: submitSendDelivery, loading: confirmLoading, totalDistance: totalDistance })));
};
export default forwardRef(OrderMapCalculate);
var templateObject_1;
